import React, { useEffect } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import BackendHeader from "../Common/BackendHeader/BackendHeader";
import BackendFooter from "../Common/BackendFooter/BackendFooter";
import "./BackendLayout.css";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setOpenSanckBar } from "./Pages/calendarModule/calendarReducer";
import { Alert, Snackbar } from "@mui/material";

const BackendLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch()
  let match = useMatch("/bio-energetics-discovery/:user_id");
  let pecematch = useMatch("/pece-discovery/:user_id");
  let intakepecematch = useMatch("/intake-pece-discovery/:user_id");
  let intakebiomatch = useMatch("/intake-bio-discovery/:user_id");
  let cancelslotmatch = useMatch("/cancel-slot/:identifier");
  let reschedulePath = useMatch("/re-schedule-appointment/:identifier");
  let thankyouPath = useMatch("/thankyou/:page");
  let organizerSlot = useMatch("/calendar-management-booking-slots");

  const googeConnectTime = useSelector((state) => state.loginUserKey.userInfo.google_calendar_connect_time)
  const connectedGmail = useSelector((state) => state.loginUserKey.userInfo.connected_gmail)
  const openSanckBar = useSelector((state) => state.calenderEventsStore.openSanckBar)
  const openSanckBarMessage = useSelector((state) => state.calenderEventsStore.openSanckBarMessage)
  const openSanckBarType = useSelector((state) => state.calenderEventsStore.openSanckBarType)


  console.log("pecematch", intakebiomatch);
  console.log("bio match", match);
  var biopathFlag = true;
  var pecepathFlag = true;
  switch (location.pathname) {
    case "/bio-energetics-discovery":
      biopathFlag = false;
      break;
    case "/pece-discovery":
      pecepathFlag = false;
      break;
    case "/bio-energetics-integration":
      pecepathFlag = false;
      break;
    case "/pece-integration":
      pecepathFlag = false;
      break;
    case "/beto-paredes-llc":
      pecepathFlag = false;
      break;

    default:
      break;
  }
  if (cancelslotmatch != undefined && cancelslotmatch != null && cancelslotmatch.pattern.path === '/cancel-slot/:identifier') {
    biopathFlag = false;
  }
  if (reschedulePath !== undefined && reschedulePath !== null && reschedulePath.pattern.path === '/re-schedule-appointment/:identifier') {
    biopathFlag = false;
  }
  if (organizerSlot !== undefined && organizerSlot !== null && organizerSlot.pattern.path === '/calendar-management-booking-slots') {
    // biopathFlag = false;
  }
  if (thankyouPath !== undefined && thankyouPath !== null && thankyouPath.pattern.path === '/thankyou/:page') {
    biopathFlag = false;
  }
  if (match != undefined && match != null && match.pattern.path === '/bio-energetics-discovery/:user_id') {
    biopathFlag = false;
  }
  if (pecematch != undefined && pecematch != null && pecematch.pattern.path === '/pece-discovery/:user_id') {
    pecepathFlag = false;
  }
  if (pecematch != undefined && pecematch != null && pecematch.pattern.path === '/pece-discovery/:user_id') {
    pecepathFlag = false;
  }
  if (intakepecematch != undefined && intakepecematch != null && intakepecematch.pattern.path === '/intake-pece-discovery/:user_id') {
    pecepathFlag = false;
  }
  if (intakebiomatch != undefined && intakebiomatch != null && intakebiomatch.pattern.path === '/intake-bio-discovery/:user_id') {
    pecepathFlag = false;
    console.log("headerss")
  }
  console.log("location", location);
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  // console.log("getcookies", getcookies);
  var userType = "";
  if (getcookies != undefined && getcookies.userInfo != undefined) {
    userType = getcookies.userInfo.type;

  }
  console.log("cookies in layout==>", userType);

  if (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/forgot-password') {
    document.body.className += ' noPadding';
  }


  // useEffect(() => {
  //   // Listen for messages from the parent
  //   window.addEventListener('message', handleMessage);

  //   return () => {
  //     // Remove the event listener when the component unmounts
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);

  // const handleMessage = (event) => {
  //   console.log("Hit in handle msg");
  //   // Check the origin of the event to ensure it's from an allowed domain
  //   // if (event.origin === 'https://dev.underpayments.betoparedes.com') {
  //     // Access the data from the parent
  //     console.log("event+++++++++++",event);
  //     const dataFromParent = event.data;
  //     console.log("data from crossdomain",dataFromParent);

  //     // You can now store this data in the local state or do other actions as needed
  //   // }
  // };

  return (
    <>
      <div className="mainContent p30TB">

        {/* {(userType === 'admin' && biopathFlag == true && pecepathFlag == true) &&
          <BackendHeader />
        } */}
        {(
          userType === 'admin' &&
          (location.pathname === '/calendar-management-createEvent'
            || location.pathname.split('/').includes('calendar-management-edit-event')
            || location.pathname.split('/').includes('available-slots-sync-google')
            || location.pathname.includes('/calendar-management-event-listing')
            || location.pathname.includes('/calendar-management-manageUser')
            || location.pathname.includes('/calendar-management-available-slots')
            || location.pathname.includes('/calendar-management-booked-slots')
            || location.pathname.includes('/calendar-management-cron-list')
            || location.pathname.includes('/events-slot-summery')
          )
        ) &&
          <BackendHeader />
        }

        {(
          location.pathname === '/calendar-management-createEvent'
          || location.pathname.split('/').includes('calendar-management-edit-event')
          || location.pathname.split('/').includes('available-slots-sync-google')
          || location.pathname.includes('/calendar-management-event-listing')
          || location.pathname.includes('/calendar-management-manageUser')
          || location.pathname.includes('/calendar-management-available-slots')
          || location.pathname.includes('/calendar-management-booked-slots')
          || location.pathname.includes('/calendar-management-cron-list')
          || location.pathname.includes('/events-slot-summery')
        ) &&
          <div className="mainContentWrapper form">
            <div className="ContentWrapper">
              <div className="Formdiv">
                <div className="FormBlock">
                  <div className="connectedemail">
                    <h2>Calender Management available slots</h2>
                    <div className="calenderinfo">
                      <p>
                        CONNECTED WITH GOOGLE CALENDAR <br /> LAST UPDATED ON : {googeConnectTime ? moment(Number(googeConnectTime)).format('DD MMMM YYYY') : 'NA'} <br />
                        EMAIL CONNECTED: <span>{connectedGmail}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }


        <div className="pageMain">
          <Outlet />
        </div>

        {(userType === 'admin') &&
          <BackendFooter />
        }


      </div>

      <Snackbar open={openSanckBar && Boolean(openSanckBarMessage) && Boolean(openSanckBarType)} autoHideDuration={6000} onClose={() => dispatch(setOpenSanckBar({ open: false, message: null, type: null }))}>
        {openSanckBar && Boolean(openSanckBarMessage) && Boolean(openSanckBarType) && <Alert onClose={() => dispatch(setOpenSanckBar({ open: false, message: null, type: null }))} severity={openSanckBarType} sx={{ width: '100%' }}>
          {openSanckBarMessage}
        </Alert>}
      </Snackbar>

    </>
  );
};

export default BackendLayout;
