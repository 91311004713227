import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Box, Button, LinearProgress } from "@mui/material";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../../../form/Form";
import ListingTable from "../../../../listing/listing";
import { ModalBox } from "../../../../listing/ModalBox";
import store from "../../../../store";
import { setTempFormUpdateData } from "../../../../form/formReducer";
import {
  clearCustomButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  setTableData,
} from "../../../../listing/listReducer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { EventSummary } from "../EventSummary/EventSummary";
import { GoogleEvents } from "../SixmonthsSlot/GoogleEvents";
import { ManageCron } from "../ManageCron/ManageCron";
import { ConflictingSlot } from "../ConflictingSlot/ConflictingSlot";
import { NoSlotsforEventList } from "../NoSlotsForEvent/NoSlotsforEventList";
import { RedundantClicksList } from "../RedundentClicks/RedundantClicksList";
import { InvalidEventList } from "../InvalidEvent/InvalidEventList";
import { EventSlotNotCreatedList } from "../EventSlotNotCreated/EventSlotNotCreatedList";

const ManageUser = () => {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();

  // console.log("cookies in layout==>", getcookies.userInfo._id);
  const [loaderFlag, setloaderFlag] = useState(true);

  // const [eventListingData, seteventListingData] = useState([]);
  const [responseData, setresponseData] = React.useState([]);
  const [useridsetdata, setuseridsetdata] = React.useState(
    getcookies.userInfo?._id
  );
  const [norecordsFlag, setnorecordsFlag] = React.useState(false);
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [editData, setEditData] = useState({});
  const [accordion, setAcccordion] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const [isScrollMiddle, setIsScrollMiddle] = useState(false);
  const [isScrollEnd, setIsScrollEnd] = useState(false);


  const loginUserId = useSelector((state) => state.loginUserKey.userInfo._id);

  const userId = useSelector(
    (state) => state.tableSlice.tempViewData.calendarUserTable
  );
  const stroreDispatch = useDispatch();

  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("storeDatastoreData", storeData);
    if (
      storeData.tableSlice.customButtonClicked.calendarUserTable &&
      storeData.tableSlice.customButtonClicked.calendarUserTable.view
    ) {
      setOpenModal(true);
      viewActionModal.current.data =
        storeData.tableSlice.tempViewData.calendarUserTable;
    }
  });

  const closeModal = () => {
    setOpenModal(false);
    stroreDispatch(
      clearCustomButtonClicked({
        tableId: "calendarUserTable",
        buttonId: "view",
      })
    );
    stroreDispatch(
      resetRowActionBtnClicked({
        tableId: "calendarUserTable",
        buttonId: "view",
      })
    );
  };

  const viewActionModal = useRef({
    id: "calendarUserTable",
    className: "modal1 custommodal modalblock",
    style: {},
    heading: "Details",
    labels: {
      name: "Full Name",
      email: "Email",
      secondary_email: "Secondary Email",
      phone: "Phone",
      secondary_phone: "Secondary Phone",
      address: "Address",
      state: "State",
      city: "City",
      zip: "Zip",
      type: "Type",
    },
    data: null,
  });

  const handleChange = (panel) => (event, isAccordion) => {
    console.log("panel====>", panel);
    setAcccordion(isAccordion ? panel : false);
  };

  const tableData = {
    tableId: "calendarUserTable",
    tableTitle: "Calendar User List",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: "api/user-list",
      tableCountEndpoint: "api/user-list-count",
      deleteSingleUserEndpoint: "api/single-user-delete",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: { userid: loginUserId },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    deleteSingleUserReqBody: {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    },
    rowsPerPageOptions: [2, 5, 20, 30],
    sortFields: ["event_title", "description"],
    // paginationType: "stackPagination",
    customBtnHead: [
      {
        id: "deleteMultiple",
        type: "delete",
        title: "Delete",
        name: "delete",
      },
      // {
      //   id: "editMultiple",
      //   type: "toggle",
      //   title: "Status Change",
      //   name: 'toggle'
      // }
    ],
    buttons: [
      {
        id: "delete",
        type: "delete",
        label: "Delete",
        name: "delete",
      },
      {
        id: "view",
        type: "view",
        label: "View",
        name: "view",
      },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    searchBarData: {
      heading: "Calendar User List Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/user-list",
        tableCountEndpoint: "api/user-list-count",
        reqBody: {
          source: "",
          condition: {
            limit: 10,
            skip: 0,
          },
          sort: {
            type: "asc",
            field: "firstname",
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [

        ],
        textsearch: [
          { id: 2, label: "Search By Full Name ", field: "name", value: "" },
          { id: 3, label: "Search By Address ", field: "address", value: "" },
          { id: 4, label: "Search By Email ", field: "email", value: "" },
          { id: 5, label: "Search By Phone ", field: "phone", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       condition: {
          //         limit: 10,
          //         skip: 0,
          //       },
          //       sort: {
          //         type: "desc",
          //         field: "_id",
          //       },
          //       searchcondition: {},
          //     },
          //   },
          // },
        ],
      },
    },
  };

  const getdata = async () => {
    console.log("useridsetdata++++++++++", useridsetdata);
    tableData.reqBody.searchcondition.userid = useridsetdata;
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(tableData.reqBody),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api/user-list",
      requestOptions
    );
    // console.log("++++response+++++++",response);

    const respdata = await response.json();
    console.log("++++cv+++++++", respdata);

    if (respdata.status == "success") {
      setresponseData([...respdata.results.res]);
      console.log(
        "=================respdata.results.res===================",
        respdata.results.res
      );
      setloaderFlag(false);

      if (responseData.length == 0) {
        setnorecordsFlag(true);
      }
      console.log("setresponseData", setresponseData);
    } else {
      setsnakbaropen(true);
      snackBarMsg("Something Wrong!");
    }
  };

  var modifyTableHeaders = [
    { val: "name", name: "Full Name" },
    { val: "address", name: "Address" },
    { val: "email", name: "Email" },
    { val: "phone", name: "Phone Number" },
    // { val: "end_date_new", name: " End Date" },
    // { val: 'status', name: 'Status' },
    // { val: 'created_at', name: 'Created by' },
    // { val: "product_id", name: "Product" },
    // { val: 'phone', name: 'phone' }
  ];

  useEffect(() => {
    getdata();
  }, []);

  useEffect(() => {
    console.log("userId======================================>", userId);
  }, [userId]);

  const formData = {
    id: "userCreateForm",
    api_url: process.env.REACT_APP_API_URL,
    endPoint: "api/addorupdateusers",
    urlPathOnSuccessfulFormSubmission: "/calendar-management-available-slots",
    formButtonClass: "submitbtnsection",
    fields: [
      //////////////////////////////////// Text Input Event Title ///////////////////////////////
      {
        id: 0,
        label: "First Name",
        name: "firstname",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.firstname
            : "",
      },

      {
        id: 1,
        label: "Last Name",
        name: "lastname",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.lastname : "",
      },

      {
        id: 2,
        label: "Address",
        name: "address",
        className: "formGroup eventTitle countDiv__column--col12",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.address : "",
        sx: { m: 1, minWidth: 120 },
      },

      {
        id: 3,
        label: "State",
        name: "state",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: new Date().getTime(),
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.state : "",
      },

      {
        id: 4,
        label: "City",
        name: "city",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        // defaultValue: new Date().getTime(),
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.city : "",
      },

      {
        id: 5,
        label: "Zip",
        name: "zip",
        className: "formGroup eventTitle countDiv__column--col6",
        inputType: "number",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.zip : "",
        sx: { m: 1, minWidth: 120 },
      },

      {
        id: 11,
        label: "Type",
        name: "type",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "select",
        values: [
          {
            val: "admin",
            name: "Admin",
          },
        ],
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.type[0] : "",
      },

      {
        id: 6,
        label: "Email",
        name: "email",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: {
          required: true,
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        },
        errorMessage: {
          required: "This Field is Required",
          pattern: "Invalid Email Address",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.email : "",
        sx: { m: 1, minWidth: 120 },
      },

      {
        id: 7,
        label: "Secondary Email",
        name: "secondary_email",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "text",
        rules: {
          required: true,
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        },
        errorMessage: {
          required: "This Field is Required",
          pattern: "Invalid Email Address",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.secondary_email
            : "",
      },

      {
        id: 9,
        label: "Phone Number",
        name: "phone",
        type: "text",
        inputType: "number",
        className: "formGroup eventTitle countDiv__column--col6",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          minLength: "Minimum length should be 15",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.phone : "",
      },

      {
        id: 10,
        label: "Secondary Phone Number",
        name: "secondary_phone",
        type: "text",
        inputType: "number",
        className: "formGroup eventTitle countDiv__column--col6",
        errorMessage: {
          required: "This Field is Required",
          minLength: "Minimum length should be 15",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.secondary_phone
            : "",
      },

      {
        id: 8,
        label: "Password",
        name: "password",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "password",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0 ? editData.password : "",
      },

      {
        id: 80,
        label: "Confirm Password",
        name: "confirm_password",
        className: "formGroup eventTitle countDiv__column--col6",
        type: "password",
        rules: { required: true },
        errorMessage: {
          required: "This Field is Required",
          errorMessageConfirmPassword: "Password does not match",
        },
        defaultValue:
          editData && Object.keys(editData).length > 0
            ? editData.confirm_password
            : "",
      },

      //////////////////////////////// Select Product/////////////////////////////////////
      // {
      //   id: 16,
      //   label: "Product",
      //   name: "product_id",
      //   className: "formGroup product countDiv__column--col12",
      //   type: "select",
      //   values: [
      //     {
      //       val: "PECE platform and TM-Flow",
      //       name: "PECE platform and TM-Flow",
      //     },
      //     {
      //       val: "BioEnergetics platform with neoGEN Series",
      //       name: "BioEnergetics platform with neoGEN Series",
      //     },
      //   ],
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //   },
      //   defaultView: "Product",
      //   defaultValue: editData && Object.keys(editData).length > 0 ? editData.product_id[0] : "",
      // },
      // {
      //   id: 16,
      //   label: "Event Details",
      //   heading: "Event Details",
      //   name: "description",
      //   className: "formGroup eventDetails sunEditor countDiv__column--col12",
      //   type: "sunEditor",
      //   rows: 4,
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //     minLength: "Minimum length 20 required",
      //   },
      //   defaultValue: editData && Object.keys(editData).length > 0 ? editData.description : ''
      // },

      //////////////////////////////// Select Secondary organizer mail/////////////////////////////////////
      // {
      //   id: 17,
      //   label: "Secondary Organizer E-mail",
      //   name: "secondaryorganizeremail",
      //   className: "formGroup secondaryorganizeremail countDiv__column--col12",
      //   type: "select",
      //   values: [
      //     {
      //       val: "PECE platform and TM-Flow",
      //       name: "PECE platform and TM-Flow",
      //     },
      //     {
      //       val: "BioEnergetics platform with neoGEN Series",
      //       name: "BioEnergetics platform with neoGEN Series",
      //     },
      //   ],
      //   rules: { required: true },
      //   errorMessage: {
      //     required: "This Field is Required",
      //   },
      //   defaultView: "Product",
      //   defaultValue: editData && Object.keys(editData).length > 0 ? editData.product_id[0] : "",
      // },
    ],
    formAdditionalSubmissionData: {
      created_by: `${loginUserId}`,
    },
  };
  window.addEventListener('scroll', function () {
    console.log("scroll", window.scrollY)
    if (window.scrollY > 1000) {
      setIsScrollMiddle(true)
    }
    if (window.scrollY > 1500) {
      setIsScrollEnd(true);
    }
  })
  // let scroll = document.documentElement.scrollTop;
  //     console.log("scroll", scroll)
  console.log("newFormData====>", formData);
  return (
    <>
      <div style={{ width: "95vw", margin: "auto" }}>
        <div className="buttonsetToolbar">
          <Button variant="contained">
            <Link to="/calendar-management-createEvent">
              Create New Availability
            </Link>
          </Button>
          <Button variant="contained">
            <Link to="/calendar-management-event-listing">
              Availability List
            </Link>
          </Button>
          <Button
            href={`https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar?url=${(window.location.href).replace(/\/$/, "")}`}
            variant="contained"
          >
            Update Google Account
          </Button>
          <Button variant="contained">
            <Link to="/calendar-management-booked-slots">Booked Slot</Link>
          </Button>

          <Button variant="contained">
            <Link to="/events-slot-summery">Event Summary</Link>
          </Button>

          {/* <Button variant="contained">
            <Link to="/calendar-management-manageUser">Manage User</Link>
          </Button> */}
        </div>

        <div style={{ textAlign: "center", marginTop: '50px', marginBottom: "20px" }}>Calendar User List</div>
        <div>
          <Accordion onChange={handleChange}>
            <AccordionSummary className="accordion_summary">
              <Button variant="contained">+ Add User</Button>
            </AccordionSummary>
            <AccordionDetails>
              <Form formData={formData} />
            </AccordionDetails>
          </Accordion>
        </div>


        <div className="reactTableWrapper">
          <div className="reactTable">
            {responseData && responseData.length > 0 ? (
              <ListingTable
                tableData={tableData}
                dataset={responseData}
                modifyHeaders={modifyTableHeaders}
              />
            ) : (
              <p>No Records Found</p>
            )}
            {/* {norecordsFlag == true && <p>No Records Found</p>} */}
          </div>
        </div>
      </div>

      <ModalBox
        open={openModal}
        close={closeModal}
        data={viewActionModal.current}
      />

      <div style={{ marginTop: "20px", width: "95vw", margin: "auto" }}>
        <EventSummary />
      </div>
      {isScrollMiddle ?
        <>
          <div style={{ marginTop: "20px", width: "95vw", margin: "auto" }}>
            <ConflictingSlot />
          </div>
          <div style={{ marginTop: "20px", width: "95vw", margin: "auto" }}>
            <GoogleEvents />
          </div>
          <div style={{ marginTop: "25px", width: "95vw", margin: "auto" }}>
            <ManageCron />
          </div>
        </>
        : ''
      }
      {isScrollEnd ? <>
        <div style={{ marginTop: "25px", width: "95vw", margin: "auto" }}>
          <NoSlotsforEventList />
        </div>
        <div style={{ marginTop: "25px", width: "95vw", margin: "auto" }}>
          <InvalidEventList />
        </div>
        <div style={{ marginTop: "25px", width: "95vw", margin: "auto" }}>
          <RedundantClicksList />
        </div>
        <div style={{ marginTop: "25px", width: "95vw", margin: "auto" }}>
          <EventSlotNotCreatedList />
        </div>

      </> : ''}

    </>
  );
};

export default ManageUser;
