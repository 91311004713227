import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchCalenderEvents,
  fetchCalenderIntegrationEvents,
  fetchCalenderPECEIntegrationEvents,
  fetchCalenderPECEIntegrationEventsmore,
  updateGoogleTokenGmail,
  booknowevents,
  fetchCalenderEventsLoadMore,
  setBooknowSubmissionState,
  fetchEventWithIdentifier,
  rescheduleevents,
  setrescheduleSubmissionStateReset,
  fetchCalenderEventsLoadMorenoconcat,
  setSlotSelected,
  getPartnerCalenderEvents,
  fetchPramanIdData,
  FetchDetails,
  saveRepLeadData,
  checkMail,
  connectedAccountInfo,
  chageReRunCalenderFlage,
  setEroorApiHit,
} from "./calendarReducer";
import "../calendarModule/calender.css";
import store from "../../../../store";
import {
  Link,
  useParams,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Menu,
  Radio,
  RadioGroup,
  Snackbar,
  TextareaAutosize,
} from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import { timeConverter } from "../../../../helper/helperFunctions";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import betoparadiseopertunityes from "../../../../Assets/jsonfiels/betoparadiseopertunityes.json";
import UserForm from "./userForm";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Image } from "@mui/icons-material";


const AvailableSlots = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [timeExceedSlotFetching, setTimeExceedSlotFetching] = useState(false);


  const [biogenixFlag, setbiogenixFlag] = useState(false);
  const [fryright, setfryright] = useState(false);


  useEffect(() => {
    const domain = window.location.origin;


    if (domain.includes('main.d3h3uuosqhiw01.amplifyapp.com') || domain.includes('biogenix') || domain.includes('3996')) {
      setbiogenixFlag(true)
    } else {
      // Optionally remove the class if the domain doesn't match
      setbiogenixFlag(false)

    }

    if (domain.includes('fryright') || domain.includes('3997')) {
      setfryright(true)

    } else {
      // Optionally remove the class if the domain doesn't match
      setfryright(false)
    }


  }, []);

  const [eventHeader, setEventHeader] = useState({});
  const [reloade, setReloade] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  const rootElement = useRef();
  const { ref: inViewRef } = useInView({ rootMargin: "500px" });

  const currentUser = useSelector((state) => state.loginUserKey.userInfo.isDev);
  const noMoreSlots = useSelector(
    (state) => state.calenderEventsStore.noMoreSlots
  );
  const slots_end = useSelector((state) => state.calenderEventsStore.slots_end);
  const bookNowload = useSelector(
    (state) => state?.calenderEventsStore?.bookNowload
  );
  const reschedule_slots_endpoint = useSelector(
    (state) => state?.calenderEventsStore?.fetchIdentifierData?.endpoint
  );
  const mailChackResult = useSelector((state) =>
    state.calenderEventsStore?.mailChackResult
      ? state.calenderEventsStore.mailChackResult
      : {}
  );
  const lead_rep_data = useSelector((state) =>
    state.calenderEventsStore?.lead_rep_data
      ? state.calenderEventsStore?.lead_rep_data
      : {}
  );
  const lead_rep_details = useSelector((state) =>
    state.calenderEventsStore?.lead_rep_details
      ? state.calenderEventsStore?.lead_rep_details
      : {}
  );

  const reRunCalenderFlage = useSelector(
    (state) => state.calenderEventsStore.reRunCalenderFlage
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const openCalendarInfo = Boolean(anchorEl);
  const [proudctID, setProudctID] = useState("");
  const [urlName, setUrlName] = useState("");
  const [urlEmail, setUrlEmail] = useState("");
  const [leadID, setleadID] = useState("");

  const [ustOffsets, setUstOffsets] = useState([]);
  const [menuItems, setmenuItems] = useState([]);



  const [user, setUser] = useState({
    full_name: "",
    dob: "",
    city: "",
    country: "",
    occupation: "",
    relationship_status: "Single",
    phone: "",
    describe_your_personality: "",
    primary_sengths_talents: "",
    life_areas_currently_fulfilling: "",
    life_areas_needing_improvement: "",
    long_term_goals_and_aspirations: "",
    major_life_events_experiences: '',
    stress_management: false,
    Work_Life_balance: false,
    current_relationship_with_family_and_friends: "",
    focus_on_specific_relationships: false,
    concerns_about_romantic_life: false,
    social_life_fulfillment: false,
    biggest_current_challenges: "",
    guidance_on_specific_decision: false,
    ongoing_projects: false,
    spiritual_or_personal_growth_practices: false,
    exploring_self_discovery: false,
    belief_in_external_influences_on_life: false,
    achieve_from_the_reading: "",
    specific_questions_topics_to_address: false,
    goals_or_outcomes_for_the_reading: false,
    Preference_for_practical_advice_vs_spiritual_insights: false,
    main_concern_or_question_right_now: "",
    anything_else_to_share: "",
    concerns_reservations_about_the_consultation: false,
    session_for: 'Astro Toolkit',
    time_of_birth: '',
    Product_Deck: '',
    DivineBaseUrl: 'https://divineinfinitebeing.influxiq.com/',
    SecondaryUrl: 'horoscope?tab=',
    selected_week: '',
    selected_month: '',
    partner_time_of_birth: '',
    partner_dob: '',
    partner_city: '',
    your_question: '',
    Product_Deck_name: ''

  });
  const [error, setError] = useState({});



  useEffect(() => {
    utcvalueUpdate();
  }, []);

  const utcvalueUpdate = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api/find-uts-offsets",
      requestOptions
    );
    const respdatautc = await response.json();
    let arrtime = [];
    for (let k in respdatautc.data) {
      let obj = {
        name: respdatautc.data[k].name,
        timeZone: `${respdatautc.data[k].utc_offset}|${respdatautc.data[k].timezone}`,
      };
      arrtime.push(obj);
      // setTimeout(()=>{
      setmenuItems(arrtime);
      let defaultvalue = arrtime?.find((data) => data.name == 'Mountain Standard Time')?.timeZone;


      settimezone(defaultvalue)
      // },[500])

    }
    setUstOffsets(respdatautc.data);
  };

  const findOffset = async (value, timezone) => {

    if (ustOffsets && ustOffsets.length > 0) {
      let findedData;
      if (timezone) {
        findedData = await ustOffsets.find((user) => user.timezone == value);
      } else {
        findedData = await ustOffsets.find((user) => user.name == value);
      }

      if (findedData && Object.keys(findedData).length > 0) {

        return `${findedData.utc_offset}|${findedData.timezone}`;
      }
    }
  };
  let [currenttimezonename, setcurrenttimezonename] = useState("");

  const [timezone, settimezone] = React.useState(currenttimezonename);
  const handleCalendarInfoClose = (path) => {
    setAnchorEl(null);
    if (path) navigate(`/${path}`);
  };

  useEffect(() => {
    if (searchParams.get("product_id")) {
      setProudctID(searchParams.get("product_id"));
    }
    if (searchParams.get("name")) {
      setUrlName(searchParams.get("name"));
    }
    if (searchParams.get("email")) {
      setUrlEmail(searchParams.get("email"));
    }
    if (searchParams.get("leadId")) {
      setleadID(searchParams.get("leadId"));
    }
  }, []);


  // useEffect(()=>{
  //   if(leadID){
  //     console.log("window.location",window.location.pathname);
  //     let obj={
  //       env:'prod',
  //       leadId:leadID
  //     }
  //     if(window.location.pathname=='/discoverycall-for-sales-dev'){
  //       obj.env='dev'
  //     }
  //     sessionStorage.setItem("bpsalesData", JSON.stringify(obj));

  //   }
  // },[leadID])


  // ==================for page reload===============
  // setTimeout(() => {
  //   window.location.href = ""
  // }, 5000)

  // useEffect(()=>{
  //   window.location.reload();
  // },[])
  useEffect(() => {
    // Set interval to reload every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(() => {
      // window.location.reload();
    }, 300000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    window.localStorage.removeItem("selectedSlot");
    window.localStorage.removeItem("fetchIdentifierData");
  }, []);

  useEffect(() => {
    const pageLoaded = sessionStorage.getItem("pageLoaded");

    if (!pageLoaded) {
      sessionStorage.setItem("pageLoaded", "true");

      // window.location.reload();
    }
  }, []);

  var reqestdatasetpece = {};
  let currenttimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!localStorage.getItem("currenttimezone")) {
    localStorage.setItem("currenttimezone", currenttimezone);
  } else {
    currenttimezone = localStorage.getItem("currenttimezone");
  }

  // let currenttimezonename = "";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const abbrs = {
    HST: "Hawaii  ",
    EDT: "Eastern Daylight ",
    EST: "Eastern  ",
    CST: "Central  ",
    CDT: "Central Daylight ",
    MST: "Mountain  ",
    MDT: "Mountain Daylight ",
    PST: "Pacific ",
    PDT: "Pacific Daylight ",
    IST: "India  ",
    AKDT: "Alaska Daylight ",
    AKST: "Alaska  ",
  };

  function selectTimeZone(currenttimezone) {
    let currenttimezonename = currenttimezone.split("|")[1];
    // switch (currenttimezone) {
    //   case "+05:30|Asia/Calcutta":
    //     currenttimezonename = "Asia/Kolkata";
    //     break;
    //   case "+05:30|Asia/Kolkata":
    //     currenttimezonename = "Asia/Kolkata";
    //     break;
    //   case currenttimezone.includes("America/Anchorage"):
    //     console.log("hit America/Anchorage");

    //     currenttimezonename = "America/Anchorage";
    //     break;
    //   case "-08:00|America/Los_Angeles":
    //     currenttimezonename = "America/Los_Angeles";
    //     break;
    //   case "-07:00|America/Denver":
    //     currenttimezonename = "America/Phoenix";
    //     break;
    //   case "-07:00|America/Phoenix":
    //     currenttimezonename = "America/Phoenix";
    //     break;
    //   case "-06:00|America/Chicago":
    //     currenttimezonename = "America/Chicago";
    //     break;
    //   case "-05:00|America/New_York":
    //     currenttimezonename = "America/New_York";
    //     break;
    //   case "-10:00|Pacific/Honolulu":
    //     currenttimezonename = "Pacific/Honolulu";
    //     break;
    // }

    return currenttimezonename;
  }

  const swithFunc = async () => {

    switch (currenttimezone) {
      case "Asia/Calcutta":
        // currenttimezonename = "+05:30|Asia/Kolkata";
        let currenttimezonenameValuecal = await findOffset(
          "Asia/Kolkata",
          true
        );

        setcurrenttimezonename(currenttimezonenameValuecal);
        settimezone(currenttimezonenameValuecal);
        break;
      case "Asia/Kolkata":
        // currenttimezonename = "+05:30|Asia/Kolkata";
        let currenttimezonenameValueasia = await findOffset(
          "Asia/Kolkata",
          true
        );


        setcurrenttimezonename(currenttimezonenameValueasia);
        settimezone(currenttimezonenameValueasia);
        break;
      case "America/Anchorage":
        let currenttimezonenameValue = await findOffset(
          "America/Anchorage",
          true
        );
        setcurrenttimezonename(currenttimezonenameValue);
        settimezone(currenttimezonenameValue);

        break;
      case "America/Los_Angeles":
        let currenttimezonenameValueLA = await findOffset(
          "America/Los_Angeles",
          true
        );

        setcurrenttimezonename(currenttimezonenameValueLA);
        settimezone(currenttimezonenameValueLA);
        break;
      case "America/Denver":
        // currenttimezonename = "-07:00|America/Phoenix";
        let currenttimezonenameDenver = await findOffset(
          "America/Denver",
          true
        );

        setcurrenttimezonename(currenttimezonenameDenver);
        settimezone(currenttimezonenameDenver);
        break;
      case "America/Phoenix":
        // currenttimezonename = "-07:00|America/Phoenix";
        let currenttimezonenamePhoenix = await findOffset(
          "America/Phoenix",
          true
        );

        setcurrenttimezonename(currenttimezonenamePhoenix);
        settimezone(currenttimezonenamePhoenix);
        break;
      case "America/Chicago":
        // currenttimezonename = "-06:00|America/Chicago";
        let currenttimezonenameChigaco = await findOffset(
          "America/Chicago",
          true
        );

        setcurrenttimezonename(currenttimezonenameChigaco);
        settimezone(currenttimezonenameChigaco);
        break;
      case "America/New_York":
        // currenttimezonename = "-05:00|America/New_York";
        let currenttimezonenameNewYork = await findOffset(
          "America/New_York",
          true
        );

        setcurrenttimezonename(currenttimezonenameNewYork);
        settimezone(currenttimezonenameNewYork);
        break;
      case "Pacific/Honolulu":
        // currenttimezonename = "-10:00|Pacific/Honolulu";
        let currenttimezonenameHonolulu = await findOffset(
          "Pacific/Honolulu",
          true
        );

        setcurrenttimezonename(currenttimezonenameHonolulu);
        settimezone(currenttimezonenameHonolulu);
        break;
      default:
        let currenttimezonenameValueDefault = await findOffset(
          "Asia/Kolkata",
          true
        );
        setcurrenttimezonename(currenttimezonenameValueDefault);
        settimezone(currenttimezonenameValueDefault);
        break;
    }
  };
  useEffect(() => {
    swithFunc();
  }, [currenttimezone, ustOffsets]);




  let match = useMatch("/bio-energetics-discovery/:user_id");
  let pecematch = useMatch("/pece-discovery/:user_id");
  let availableslotpathmatch = useMatch(
    "/calendar-management/available-slots-sync-google/:code/:secrect"
  );
  let reschedulePath = useMatch("/re-schedule-appointment/:identifier");
  let intakebiomatch = useMatch("/intake-bio-discovery/:user_id");
  let intakepecematch = useMatch("/intake-pece-discovery/:user_id");
  let newMatch = useMatch("/pece-integration-new/:user_id");
  let aspirePartnermatch = useMatch("/beto-paredes-aspire-partners/:user_id");
  let fromAspirePartnerMatch = useMatch(
    "/beto-paredes-from-aspire-partners/:user_id"
  );
  let fromAspirePartnerWithJamesMatch = useMatch(
    "/beto-paredes-from-aspire-partners-with-james/:user_id"
  );
  let partnerMatchWithJohn = useMatch(
    "/aspire-partners-with-beto-jhon/:user_id"
  );
  let partnerMatchWithKatherine = useMatch(
    "/aspire-partners-with-beto-katherine/:user_id"
  );
  let partnerMatchWithTodd = useMatch(
    "/aspire-partners-with-beto-todd/:user_id"
  );
  let partnerMatchWithSutopa = useMatch(
    "/aspire-partners-with-soumya-sutopa/:user_id"
  );
  let partnerMatchWithMoumita = useMatch(
    "/aspire-partners-with-soumya-moumita/:user_id"
  );
  let partnerMatchWithDebasis = useMatch(
    "/aspire-partners-with-soumya-sudeb/:user_id"
  );
  let partnerMatchWithJeannette = useMatch(
    "/aspire-partners-with-jeannette/:user_id"
  );
  let partnerMatchWithADCH = useMatch("/ADHC-underpayments-meeting/:user_id");
  let partnerMatchWithADCHTest = useMatch(
    "/ADHC-underpayments-meeting-test/:user_id"
  );

  let meetingWithDivineAdmin = useMatch("/meeting-with-divine-admin/:user_id");
  let cancerMatch = useMatch("/:user_id");
  let partnerMatchWithDiscoveryCallDev = useMatch(
    "/discoverycall-for-sales-dev/:user_id"
  );
  let partnerMatchWithDiscoveryCallProd = useMatch(
    "/discoverycall-for-sales/:user_id"
  );
  let partnerMatchWithStrategyCallNathanDev = useMatch(
    "/gameplan-call-for-sales-dev/:user_id"
  );
  let partnerMatchWithStrategyCallNathanProd = useMatch(
    "/gameplan-call-for-sales/:user_id"
  );

  // let partnerMatchWithStrategyCallNathanProd = useMatch(
  //   "/strategy-call-for-sales/:user_id"
  // );

  let precticePrecenceRoute = useMatch(
    "/practice-presence-cancer-discovery/:user_id"
  );



  var paramId;
  let preformdata;

  ////////////////////////// Setting attende data by default //////////////////
  if (getcookies.middleware !== undefined) {
    preformdata = {
      name: "",
      shareholders: [
        {
          name: getcookies.middleware.email,
          attendeName: `${getcookies.middleware.first_name} ${getcookies.middleware.last_name}`,
        },
      ],
    };
  } else {
    preformdata = {
      name: "",
      shareholders: [{ name: "", attendeName: "" }],
    };
  }
  let urlParams = new URLSearchParams(window.location.search);
  let name = urlParams.get("name");
  let email = urlParams.get("email");
  let id = urlParams.get("id");
  if (name && email) {
    if (!getcookies.middleware) {
      preformdata = {
        name: "",
        shareholders: [{ name: email, attendeName: `${name}` }],
      };
    }
  } else {
    // console.log("preformdata",preformdata);
  }

  const [open, setOpen] = React.useState(false);
  const [devflag, setdevflag] = React.useState(false);
  const [clickableData, setclickableData] = React.useState({});
  const [formArrayData, setformArrayData] = React.useState(preformdata);
  const [snakbaropen, setsnakbaropen] = React.useState(false);

  const [convertedTimezone, setConvertedTimezone] =
    React.useState(currenttimezone);
  const [daterangeValue, setdaterangeValue] = React.useState([null, null]);
  const [bookNowSubmissionState, setBookNowSubmissionState] = useState(0);
  const [eventId, setEventId] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [rescheduleParamID, setRescheduleParamID] = useState(null);
  // const [notes, setNotes] = useState("");
  // const [notes, setNotes] = useState("");
  const notes = useRef("");

  const [cancelslotFlag, setcancelslotFlag] = useState(true);
  const [attendeeError, setAttendeeError] = useState(false);



  const conflictQueryParam = searchParams.get("conflict");

  const requestData = useRef({
    source: "events_eventdayarr_view",
    condition: {
      // "userid": getcookies.userInfo._id,
    },
    limit: 20,
    skip: 0,
    conflict_slots:
      conflictQueryParam == 1 ? Number(conflictQueryParam) : undefined,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
  });

  useEffect(() => {
    if (
      slots_end === false &&
      ((timeExceedSlotFetching == true && calenderEventsData.length < 51) ||
        timeExceedSlotFetching == false)
    )
      handleLoadMore();
    else if (slots_end == true && calenderEventsData.length == 0) {
      if (requestData.current.condition["endtimeunixval"])
        delete requestData.current.condition["endtimeunixval"];
      setTimeExceedSlotFetching(true);
      handleLoadMore();
    }
  }, [slots_end]);

  useEffect(() => {
    if (
      getcookies != undefined &&
      getcookies.userInfo != undefined &&
      getcookies.userInfo.isDev != undefined &&
      getcookies.userInfo.isDev == 1
    )
      setdevflag(true);
  }, [devflag]);

  getcookies.google_calendar_connect_time = parseInt(
    getcookies.google_calendar_connect_time
  );
  getcookies.google_calendar_connect_time = moment(
    getcookies.google_calendar_connect_time
  ).format("DD-MMM-YYYY");

  const [connectgmail, setconnectgmail] = useCookies(["connectgmail"]);
  const [userID, setconnectuserid] = useCookies(["_id"]);
  const userCalenderID = userID?.userInfo?._id;
  const code = useParams();
  var accessCode = "";
  var location = useLocation();
  var pathFlag = true;
  var pecepathFlag = false;
  var betopersonalpathFlag = false;
  var biopathFlag = false;
  var intakebiopathFlag = false;
  var intakepecepathFlag = false;
  var rescheduleFlag = false;
  var aspirePartnersFlag = false;
  var aspirePartnersFlagJeannette = false;
  var nathanV3flag = false;
  var betov3flag = false;
  let fromAspirePartnersFlag = false;
  let fromAspirePartnersWithJamesFlag = false;
  let meetingWithDivineAdminFlag = false;
  let decisionDocFlag = false;
  let fromReviewAspirePharmacyFlag = false;
  let fromReviewBluecoastPharmacyFlag = false;
  let fromReviewAspireUnderpaymentsFlag = false;
  let fromReviewBluecoastUnderpaymentsFlag = false;
  let fromReviewAccessHealthUnderpaymentsFlag = false;
  let fromReviewAccessHealthPharmacyFlag = false;
  let underpaymentDiscoverWithLucas = false;
  let practice2LabMeetingWithLucas = false;
  let practicepresencecancer = false;
  var devineFlag = false;
  let otherPathsFlag = false;
  let partnerWithJohnFlag = false;
  let partnerWithKatherineFlag = false;
  let partnerWithToddFlag = false;
  let partnerWithSutopaFlag = false;
  let partnerWithMoumitaFlag = false;
  let partnerWithDebasisFlag = false;
  let practice2labFlag = false;
  let clinicalspeedFlag = false;
  let underpaymentsFlag = false;
  let bpsaleslucas = false;
  let bpsalesNathan = false;
  let bpsalesDaniel = false;

  let betoMedicalSingle = false
  let courtneyMedical = false
  let bryenMedical = false
  let betoMedicalCoutr = false
  let betoMedicalCoutrClinical = false

  let onePintCarePrecticePrecenceFlage = false;
  let croutnyFlagBackoffice = false;
  let croutnyFlagBackofficeGameplan = false;
  let jaredFlag = false

  const locationnew = useLocation();
  const { pathname } = locationnew;

  useEffect(() => {
    console.log("pathname", pathname);

    switch (pathname) {
      case "/practice-2lab":
        import("../calendarModule/Practice2Lab.css");
        document.body.classList.add("practice2lab");
        const favicon1 = document.querySelector('link[rel="icon"]');
        if (favicon1) {
          favicon1.href =
            "https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_favicon.ico";
        }
        break;

      case "/clinical-speed":
        import("../calendarModule/clinicalspeed.css");
        document.body.classList.add("clinicalspeed");
        const favicon2 = document.querySelector('link[rel="icon"]');
        if (favicon2) {
          favicon2.href =
            "https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/favicon.ico";
        }
        break;

      case "/under-payments":
        import("../calendarModule/underpayments.css");
        document.body.classList.add("underpayments");
        const favicon3 = document.querySelector('link[rel="icon"]');
        if (favicon3) {
          favicon3.href =
            "https://all-frontend-assets.s3.amazonaws.com/aspire-lead-fire-nest/aspireN_healthcare_favicon.webp";
        }
        break;

      case "/:user_id":
        import("../calendarModule/practicepresence-cancer.css");
        document.body.classList.add("practicepresence-cancer");
        const favicon4 = document.querySelector('link[rel="icon"]');
        if (favicon4) {
          favicon4.href =
            "https://all-frontend-assets.s3.amazonaws.com/practice2lab/practice2lab_favicon.ico";
        }
        break;


      default:
        break;
    }
  }, [pathname]);

  useEffect(() => {
    const previousPath = window.localStorage.getItem("previousPath");
    const currentPath = location.pathname;
    if (previousPath && previousPath !== currentPath) {
    }
    window.localStorage.setItem("previousPath", currentPath);
  }, [location.pathname]);

  switch (location.pathname) {
    case "/bio-energetics-discovery-call":
      pathFlag = false;
      biopathFlag = true;
      paramId = "627d0c580bbdabff170cd79f";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/pece-discovery":
      pathFlag = false;
      pecepathFlag = true;
      paramId = "627babf034b56402d1e77347";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/pece-integration":
      pathFlag = false;
      intakepecepathFlag = true;
      paramId = "627d0d3e0bbdabff170cd7a1";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/bio-energetics-integration":
      pathFlag = false;
      intakebiopathFlag = true;
      paramId = "627d0cdc0bbdabff170cd7a0";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-llc":
      pathFlag = false;
      betopersonalpathFlag = true;
      paramId = "629dbe769bc4d7a3761c979d";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-aspire-partners":
      aspirePartnersFlag = true;
      pathFlag = false;
      paramId = "642c17862bf2e6ede756d661";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/aspire-partners-with-jeannette":
      aspirePartnersFlagJeannette = true;
      pathFlag = false;
      // paramId = "659d30c8fddb573879253cc2";
      paramId = "65dc14396046471d47224899";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/ADHC-underpayments-meeting":
      aspirePartnersFlagJeannette = true;
      pathFlag = false;
      // paramId = "659d30c8fddb573879253cc2";
      paramId = "65dc14396046471d47224899";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/ADHC-underpayments-meeting-test":
      aspirePartnersFlagJeannette = true;
      pathFlag = false;
      // paramId = "659d30c8fddb573879253cc2";
      paramId = "65dc14396046471d47224899";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/discoverycall-for-sales-dev":
      croutnyFlagBackoffice = true;
      pathFlag = false;
      paramId = "66e7daaca2940af07f786702";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/discoverycall-for-sales": //<------------------for prod-------------
      croutnyFlagBackoffice = true;
      pathFlag = false;
      paramId = "66ed475ccd01e909cb05abb2";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/gameplan-call-for-sales-dev":
      croutnyFlagBackofficeGameplan = true;
      pathFlag = false;
      paramId = "66e7daaca2940af07f786702";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-appointment":
      betoMedicalSingle = true;
      pathFlag = false;
      paramId = "672aed0189b2527ff89ee959";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-for-medical":
      betoMedicalCoutr = true;
      pathFlag = false;
      paramId = "672b140589b2527ff89ee969";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/bp-sales-meeting":
      betoMedicalCoutrClinical = true;
      pathFlag = false;
      paramId = "67cfe3fae7ba19340b03c024";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/bp-sales-meeting-with-nick":
      betoMedicalCoutrClinical = true;
      pathFlag = false;
      paramId = "67d2fb170691fadb1c66fceb";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/jared-brague-appointment":
      jaredFlag = true;
      pathFlag = false;
      paramId = "67a48fe7ec983d1e9b4dea81";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/jared-brague-appointment/":
      jaredFlag = true;
      pathFlag = false;
      paramId = "67a48fe7ec983d1e9b4dea81";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/jared-brague-appointment-test":
      jaredFlag = true;
      // pathFlag = false;
      paramId = "67a48104ec983d1e9b4dea7f";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/jared-brague-appointment-test/":
      jaredFlag = true;
      pathFlag = false;
      paramId = "67a48104ec983d1e9b4dea7f";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/courtney-for-medical":
      courtneyMedical = true;
      pathFlag = false;
      paramId = "672af58589b2527ff89ee95d";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/bryen-for-medical":
      bryenMedical = true;
      pathFlag = false;
      paramId = "672af78389b2527ff89ee960";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;



    case "/gameplan-call-for-sales":
      croutnyFlagBackofficeGameplan = true;
      pathFlag = false;
      paramId = "66ed4482cd01e909cb05abad";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/strategy-call-for-sales": //----------------for prod--------------
      nathanV3flag = true;
      pathFlag = false;
      paramId = "65c370e5296f13cfbab6adb4";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/divineinfinitebeing":
      devineFlag = true;
      pathFlag = false;
      paramId = "6746ba69cf1c95932f2c11dc";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/divineinfinitebeingtest":
      devineFlag = true;
      pathFlag = false;
      paramId = "67d28bba4292fe31c53c1212";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;



    case "/beto-paredes-from-aspire-partners":
      fromAspirePartnersFlag = true;
      pathFlag = false;
      paramId = "641a910787405a8da413ed81";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-from-aspire-partners-with-james":
      fromAspirePartnersWithJamesFlag = true;
      pathFlag = false;
      paramId = "647ec2e3a725c7890c421e5d";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-decision-doc":
      decisionDocFlag = true;
      pathFlag = false;
      paramId = "642da12f2139cb9bbab72b93";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-review-aspire-pharmacy":
      fromReviewAspirePharmacyFlag = true;
      pathFlag = false;
      paramId = "645b27230308b0288a86a4fb";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-review-bluecoast-pharmacy":
      fromReviewBluecoastPharmacyFlag = true;
      pathFlag = false;
      paramId = "645b27550308b0288a86a4fd";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-review-aspire-underpayments":
      fromReviewAspireUnderpaymentsFlag = true;
      pathFlag = false;
      paramId = "645b27d70308b0288a86a501";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-review-bluecoast-underpayments":
      fromReviewBluecoastUnderpaymentsFlag = true;
      pathFlag = false;
      paramId = "645b27af0308b0288a86a4ff";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-review-access-health-underpayments":
      fromReviewAccessHealthUnderpaymentsFlag = true;
      pathFlag = false;
      paramId = "645b28440308b0288a86a503";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/beto-paredes-review-access-health-pharmacy":
      fromReviewAccessHealthPharmacyFlag = true;
      pathFlag = false;
      paramId = "645b28a30308b0288a86a505";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/underpayment-discovery-meeting-with-lucas":
      underpaymentDiscoverWithLucas = true;
      pathFlag = false;
      paramId = "648c5312e05d06ebe20a472b";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;
    case "/practice2lab-meeting-with-lucas":
      practice2LabMeetingWithLucas = true;
      pathFlag = false;
      paramId = "64c8f78994168790ba35b6bd";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    case "/practice-2lab":
      practice2labFlag = true;
      pathFlag = false;
      paramId = "6515500da36dbbccdb0945dd";
      otherPathsFlag = true;
      requestData.current.limit = 9;

      break;
    case "/clinical-speed":
      clinicalspeedFlag = true;
      pathFlag = false;
      paramId = "6515518ea36dbbccdb0945df";
      otherPathsFlag = true;
      requestData.current.limit = 9;

      break;
    case "/under-payments":
      underpaymentsFlag = true;
      pathFlag = false;
      paramId = "6515512ca36dbbccdb0945de";
      otherPathsFlag = true;
      requestData.current.limit = 9;
      break;

    // case "/practicepresence-cancer-slots-booking/:user_id":
    //   practicepresencecancer = true
    //   pathFlag = false;
    //   paramId = "64c8f78994168790ba35b6bd";
    //   otherPathsFlag = true;
    //   requestData.current.limit = 9;
    //   break;

    // <------- Individual partner Booking Page section Start ------->
    case "/aspire-partners-with-beto-jhon":
      otherPathsFlag = true;
      partnerWithJohnFlag = true;
      pathFlag = false;
      paramId = ["642c17862bf2e6ede756d661", "642bb8af2bf2e6ede756d64c"];
      requestData.current.limit = 9;
      break;
    case "/aspire-partners-with-beto-katherine":
      otherPathsFlag = true;
      partnerWithJohnFlag = true;
      pathFlag = false;
      paramId = ["642c17862bf2e6ede756d661", "642bb8fe2bf2e6ede756d64d"];
      requestData.current.limit = 9;
      break;
    case "/aspire-partners-with-beto-todd":
      otherPathsFlag = true;
      partnerWithJohnFlag = true;
      pathFlag = false;
      paramId = ["642c17862bf2e6ede756d661", "642bb97d2bf2e6ede756d64e"];
      requestData.current.limit = 9;
      break;
    case "/aspire-partners-with-soumya-sutopa":
      otherPathsFlag = true;
      partnerWithJohnFlag = true;
      pathFlag = false;
      paramId = ["636d118f7e7a6170f6b333d6", "641a8b0c87405a8da413ed80"];
      requestData.current.limit = 9;
      break;
    case "/aspire-partners-with-soumya-moumita":
      otherPathsFlag = true;
      partnerWithJohnFlag = true;
      pathFlag = false;
      paramId = ["636d118f7e7a6170f6b333d6", "627222ec046c4b0923276006"];
      requestData.current.limit = 9;
      break;
    case "/aspire-partners-with-soumya-sudeb":
      otherPathsFlag = true;
      partnerWithJohnFlag = true;
      pathFlag = false;
      paramId = ["636d118f7e7a6170f6b333d6", "640814b5b267bb98e652feb2"];
      requestData.current.limit = 9;
      break;
    case "/salesbackoffice-with-lucas":
      otherPathsFlag = true;
      bpsaleslucas = true;
      pathFlag = false;
      paramId = "652fc398d26292fa81c4ed99";
      requestData.current.limit = 9;
      break;

    case "/salesbackoffice-with-with-nathan":
      otherPathsFlag = true;
      bpsalesNathan = true;
      pathFlag = false;
      paramId = "652fc3e0d26292fa81c4ed9a";
      requestData.current.limit = 9;
      break;

    case "/meeting-with-divine-admin":
      meetingWithDivineAdminFlag = true;
      // bpsalesNathan = true;
      pathFlag = false;
      paramId = "65813a3107ec58aa969b9263";
      requestData.current.limit = 9;
      break;

    case "/salesbackoffice-with-daniel":
      otherPathsFlag = true;
      bpsalesDaniel = true;
      pathFlag = false;
      paramId = "652fc40dd26292fa81c4ed9b";
      requestData.current.limit = 9;
      break;
    // <------- Individual partner Booking Page section End ------->
    default:
      paramId = "";
      break;
  }

  requestData.current.limit = 10;

  if (
    match != undefined &&
    match.pattern.path === "/bio-energetics-discovery/:user_id"
  ) {
    pathFlag = false;
    biopathFlag = true;
    paramId = match.params.user_id;
  }
  if (
    newMatch != undefined &&
    newMatch.pattern.path === "/pece-integration-new/:user_id"
  ) {
    pathFlag = false;
    intakepecepathFlag = true;
    paramId = newMatch.params.user_id;
  }
  if (pecematch && pecematch.pattern.path === "/pece-discovery/:user_id") {
    pathFlag = false;
    pecepathFlag = true;
    paramId = pecematch.params.user_id;
  }
  if (
    intakebiomatch &&
    intakebiomatch.pattern.path === "/intake-bio-discovery/:user_id"
  ) {
    pathFlag = false;
    intakebiopathFlag = true;
    paramId = intakebiomatch.params.user_id;
  }
  if (
    intakepecematch &&
    intakepecematch.pattern.path === "/intake-pece-discovery/:user_id"
  ) {
    pathFlag = false;
    intakepecepathFlag = true;
    paramId = intakepecematch.params.user_id;
  }
  if (
    aspirePartnermatch &&
    aspirePartnermatch.pattern.path === "/beto-paredes-aspire-partners"
  ) {
    aspirePartnersFlag = true;
    pathFlag = false;
    paramId = aspirePartnermatch.params.user_id;
  }
  if (
    partnerMatchWithJeannette &&
    partnerMatchWithJeannette.pattern.path === "/aspire-partners-with-jeannette"
  ) {
    aspirePartnersFlagJeannette = true;
    pathFlag = false;
    paramId = partnerMatchWithJeannette.params.user_id;
  }
  if (
    partnerMatchWithADCH &&
    partnerMatchWithADCH.pattern.path === "/ADHC-underpayments-meeting"
  ) {
    aspirePartnersFlagJeannette = true;
    pathFlag = false;
    paramId = partnerMatchWithADCH.params.user_id;
  }
  if (
    partnerMatchWithADCHTest &&
    partnerMatchWithADCHTest.pattern.path === "/ADHC-underpayments-meeting-test"
  ) {
    aspirePartnersFlagJeannette = true;
    pathFlag = false;
    paramId = partnerMatchWithADCHTest.params.user_id;
  }

  if (
    partnerMatchWithDiscoveryCallDev &&
    partnerMatchWithDiscoveryCallDev.pattern.path ===
    "/discoverycall-for-sales-dev"
  ) {
    croutnyFlagBackoffice = true;
    pathFlag = false;
    paramId = partnerMatchWithDiscoveryCallDev.params.user_id;
  }

  if (
    partnerMatchWithDiscoveryCallProd &&
    partnerMatchWithDiscoveryCallProd.pattern.path ===
    "/discoverycall-for-sales"
  ) {
    croutnyFlagBackoffice = true;
    pathFlag = false;
    paramId = partnerMatchWithDiscoveryCallProd.params.user_id;
  }

  if (
    partnerMatchWithStrategyCallNathanDev &&
    partnerMatchWithStrategyCallNathanDev.pattern.path ===
    "/gameplan-call-for-sales-dev"
  ) {
    croutnyFlagBackofficeGameplan = true;
    pathFlag = false;
    paramId = partnerMatchWithStrategyCallNathanDev.params.user_id;
  }




  if (
    partnerMatchWithStrategyCallNathanDev &&
    partnerMatchWithStrategyCallNathanDev.pattern.path ===
    "/gameplan-call-for-sales"
  ) {
    croutnyFlagBackofficeGameplan = true;
    pathFlag = false;
    paramId = partnerMatchWithStrategyCallNathanDev.params.user_id;
  }

  if (
    partnerMatchWithStrategyCallNathanProd &&
    partnerMatchWithStrategyCallNathanProd.pattern.path ===
    "/strategy-call-for-sales"
  ) {
    nathanV3flag = true;
    pathFlag = false;
    paramId = partnerMatchWithStrategyCallNathanProd.params.user_id;
  }

  if (
    partnerMatchWithStrategyCallNathanProd &&
    partnerMatchWithStrategyCallNathanProd.pattern.path ===
    "/divineinfinitebeing"
  ) {
    devineFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithStrategyCallNathanProd.params.user_id;
  }

  if (
    partnerMatchWithStrategyCallNathanProd &&
    partnerMatchWithStrategyCallNathanProd.pattern.path ===
    "/divineinfinitebeingtest"
  ) {
    devineFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithStrategyCallNathanProd.params.user_id;
  }







  if (
    fromAspirePartnerMatch &&
    fromAspirePartnerMatch.pattern.path === "/beto-paredes-from-aspire-partners"
  ) {
    fromAspirePartnersFlag = true;
    pathFlag = false;
    paramId = fromAspirePartnerMatch.params.user_id;
  }
  if (
    fromAspirePartnerMatch &&
    fromAspirePartnerMatch.pattern.path ===
    "/beto-paredes-from-aspire-partners-with-james"
  ) {
    fromAspirePartnersWithJamesFlag = true;
    pathFlag = false;
    paramId = fromAspirePartnerWithJamesMatch.params.user_id;
  }

  if (
    meetingWithDivineAdmin &&
    meetingWithDivineAdmin.pattern.path === "/meeting-with-divine-admin"
  ) {
    meetingWithDivineAdminFlag = true;
    pathFlag = false;
    paramId = meetingWithDivineAdmin.params.user_id;
  }
  console.log("cancerMatch", cancerMatch);

  if (
    cancerMatch != undefined &&
    cancerMatch.pattern.path === "/:user_id" &&
    searchParams.get("source")
  ) {
    pathFlag = false;
    practicepresencecancer = true;
    paramId = cancerMatch.params.user_id;
    import("../calendarModule/practicepresence-cancer.css");

    document.body.classList.add("practicepresence-cancer");

    const favicon4 = document.querySelector('link[rel="icon"]');
    if (favicon4) {
      favicon4.href =
        "https://all-frontend-assets.s3.amazonaws.com/betoparedes-v3/favicon.ico";
    }
  }


  if (
    cancerMatch != undefined &&
    cancerMatch.pattern.path === "/:user_id" &&
    searchParams.get("org_name")
  ) {
    pathFlag = false;
    practicepresencecancer = true;
    paramId = cancerMatch.params.user_id;

  }


  if (
    partnerMatchWithJohn &&
    partnerMatchWithJohn.pattern.path === "/aspire-partners-with-beto-jhon"
  ) {
    partnerWithJohnFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithJohn.params.user_id;
  }
  if (
    partnerMatchWithKatherine &&
    partnerMatchWithKatherine.pattern.path ===
    "/aspire-partners-with-beto-katherine"
  ) {
    partnerWithKatherineFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithKatherine.params.user_id;
  }
  if (
    partnerMatchWithTodd &&
    partnerMatchWithTodd.pattern.path === "/aspire-partners-with-beto-todd"
  ) {
    partnerWithToddFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithTodd.params.user_id;
  }
  if (
    partnerMatchWithSutopa &&
    partnerMatchWithSutopa.pattern.path ===
    "/aspire-partners-with-soumya-sutopa"
  ) {
    partnerWithSutopaFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithSutopa.params.user_id;
  }
  if (
    partnerMatchWithMoumita &&
    partnerMatchWithMoumita.pattern.path ===
    "/aspire-partners-with-soumya-moumita"
  ) {
    partnerWithMoumitaFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithMoumita.params.user_id;
  }
  if (
    partnerMatchWithDebasis &&
    partnerMatchWithDebasis.pattern.path ===
    "/aspire-partners-with-soumya-sudeb"
  ) {
    partnerWithDebasisFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithDebasis.params.user_id;
  }

  if (
    partnerMatchWithDebasis &&
    partnerMatchWithDebasis.pattern.path === "/practice-2lab"
  ) {
    practice2labFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithDebasis.params.user_id;
  }
  if (
    partnerMatchWithDebasis &&
    partnerMatchWithDebasis.pattern.path === "/clinical-speed"
  ) {
    clinicalspeedFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithDebasis.params.user_id;
  }
  if (
    partnerMatchWithDebasis &&
    partnerMatchWithDebasis.pattern.path === "/under-payments"
  ) {
    underpaymentsFlag = true;
    pathFlag = false;
    paramId = partnerMatchWithDebasis.params.user_id;
  }
  if (
    precticePrecenceRoute &&
    precticePrecenceRoute.pattern.path ===
    "/practice-presence-cancer-discovery/:user_id"
  ) {
    onePintCarePrecticePrecenceFlage = true;
    pathFlag = false;
    paramId = precticePrecenceRoute.params.user_id;
  }

  useEffect(() => {
    if (
      availableslotpathmatch &&
      availableslotpathmatch.pattern &&
      availableslotpathmatch.pattern.path ===
      "/calendar-management/available-slots-sync-google/:code/:secrect"
    ) {
      accessCode = code.code + "/" + code.secrect;
      var requestDataforgoogleapi = {
        user_id: getcookies.userInfo?._id,
        code: accessCode,
      };
      dispatch(updateGoogleTokenGmail(requestDataforgoogleapi));
    }
  }, []);

  if (
    reschedulePath &&
    reschedulePath.pattern?.path === "/re-schedule-appointment/:identifier"
  ) {
    rescheduleFlag = true;
    pathFlag = false;
  }

  var reqestdatasetbio;
  const [calenderEventsData, setcalenderEventsData] = useState([]);
  const [loaderFlag, setloaderFlag] = useState(true);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [rescheduleSubmissionState, setrescheduleSubmissionState] = useState(0);
  const [attendeeemaildata, setattendeeemaildata] = useState([]);

  const pecedatasetloadmore = useRef({
    source: "events_eventdayarr_view",
    condition: {
      userid1: "627d0d3e0bbdabff170cd7a1",
    },
    limit: 100,
    skip: 0,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
  });

  const biodatasetloadmore = useRef({
    source: "events_eventdayarr_view",
    condition: {
      userid1: "627d0d3e0bbdabff170cd7a1",
      userid2: "627d0cdc0bbdabff170cd7a0",
    },
    limit: 100,
    skip: 0,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
  });

  if (paramId) requestData.current.condition["userid"] = paramId;
  else requestData.current.condition["userid"] = getcookies.userInfo?._id;

  useEffect(() => {
    setTimeout(() => {

      console.log(paramId, "currentparamId", pathname)
      if (pathname == '/bp-sales-meeting-with-nick' || pathname == '/bp-sales-meeting') {
        // document.addEventListener('DOMContentLoaded', function () {
        const banner = document.querySelector('.BioEnergetics_banner1_bg');
        const banner2 = document.querySelector('.BioEnergetics_banner1');
        if (banner) {
          console.log(".BioEnergetics_banner1", banner)
          banner.style.backgroundImage = 'none';
        }
        if (banner2) {
          console.log(".BioEnergetics_banner1", banner)
          banner2.style.background = 'linear-gradient(90deg, rgb(204, 255, 204) 0%, rgb(46, 139, 87) 100%)';
        }
        // });

      }
    }, 2000);


  }, []);

  useEffect(() => {
    if (rescheduleParamID) {
      requestData.current.condition["userid"] = rescheduleParamID;
      otherPathsFlag || reschedule_slots_endpoint
        ? dispatch(
          getPartnerCalenderEvents({
            req: requestData.current,
            loadMore: false,
          })
        )
        : dispatch(fetchCalenderEvents(requestData.current));
    }
  }, [rescheduleParamID]);

  useEffect(() => {
    if (rescheduleFlag) {
      const requestbody = {
        identifier: reschedulePath.params.identifier,
      };
      dispatch(fetchEventWithIdentifier(requestbody));
    } else {
      if (intakebiopathFlag == true) {
        reqestdatasetbio = {
          source: "events_eventdayarr_view",
          condition: {
            userid1: "627d0d3e0bbdabff170cd7a1",
            userid2: "627d0cdc0bbdabff170cd7a0",
          },
          limit: 100,
          skip: 0,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
        };
        dispatch(fetchCalenderIntegrationEvents(reqestdatasetbio));
      } else if (intakepecepathFlag == true) {
        if (!location.pathname.includes("627d0cdc0bbdabff170cd7a0")) {
          reqestdatasetpece = {
            source: "events_eventdayarr_view",
            condition: {
              userid1: "627d0d3e0bbdabff170cd7a1",
            },
            limit: 100,
            skip: 0,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
          };
          dispatch(fetchCalenderPECEIntegrationEvents(reqestdatasetpece));
        } else {
          reqestdatasetpece = {
            source: "events_eventdayarr_view",
            condition: {
              userid: "627d0cdc0bbdabff170cd7a0",
              event_type: 4,
            },
            limit: 100,
            skip: 0,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
          };
          otherPathsFlag
            ? dispatch(
              getPartnerCalenderEvents({
                req: reqestdatasetpece,
                loadMore: true,
              })
            )
            : dispatch(fetchCalenderEventsLoadMore(reqestdatasetpece));
        }
      } else {
        // runing this
        otherPathsFlag
          ? dispatch(
            getPartnerCalenderEvents({
              req: requestData.current,
              loadMore: false,
            })
          )
          : dispatch(fetchCalenderEvents(requestData.current));
      }
    }
  }, [rescheduleFlag]);

  store.subscribe(() => {
    // setInterval(() => {

    const storeData = store.getState();
    if (
      storeData.calenderEventsStore.fetchIdentifierData &&
      Object.keys(storeData.calenderEventsStore.fetchIdentifierData).length > 0
    ) {
      if (storeData.calenderEventsStore.fetchIdentifierData.status === 2)
        setcancelslotFlag(false);
      else {
        setattendeeemaildata(
          storeData.calenderEventsStore.fetchIdentifierData.atten_email
        );
        if (storeData.calenderEventsStore.fetchIdentifierData.condition.userid)
          setRescheduleParamID(
            storeData.calenderEventsStore.fetchIdentifierData.condition.userid
          );
        else
          setRescheduleParamID(
            storeData.calenderEventsStore.fetchIdentifierData.userid
          );
      }
    }
    if (conflictQueryParam) {
      setcalenderEventsData(storeData.calenderEventsStore.conflictData);
    } else {
      setcalenderEventsData(storeData.calenderEventsStore.calenderEventsData);

      let dataset = JSON.parse(
        JSON.stringify(storeData.calenderEventsStore.calenderEventsData)
      );

      if (
        proudctID &&
        betoparadiseopertunityes.length > 0 &&
        location.pathname !== "/gameplan-call-for-sales-dev" &&
        location.pathname !== "/strategy-call-for-sales"
      ) {
        let title = null;
        for (let j in betoparadiseopertunityes) {
          if (betoparadiseopertunityes[j]._ids.includes(proudctID)) {
            title = betoparadiseopertunityes[j].title;
          }
        }
        if (title) {
          for (let b in dataset) {
            dataset[b].event_title = title;
          }
        }
      }
      // proudctID
      // betoparadiseopertunityes

      setcalenderEventsData(dataset);
    }
    setloaderFlag(storeData.calenderEventsStore.loading);
    setBookNowSubmissionState(
      storeData.calenderEventsStore.bookNowSubmissionState
    );

    setEventId(
      storeData.calenderEventsStore.eventId
    );

    setIdentifier(
      storeData.calenderEventsStore.identifier
    );

    setrescheduleSubmissionState(
      storeData.calenderEventsStore.rescheduleSubmissionState
    );
    if (storeData.calenderEventsStore.booknowData === "success") {
      setsnakbaropen(true);
      setOpen(false);
      setSnackBarMsg(storeData.calenderEventsStore.booknowMsg);
    }
    if (
      storeData.calenderEventsStore.rescheduleData === "success" &&
      storeData.calenderEventsStore.rescheduleSubmissionState === 2
    ) {
      setsnakbaropen(true);
      setOpen(false);
      setSnackBarMsg(storeData.calenderEventsStore.rescheduleMsg);
    }
    if (
      storeData.calenderEventsStore.syncGoogleAcountData &&
      Object.keys(storeData.calenderEventsStore.syncGoogleAcountData).length >
      0 &&
      storeData.calenderEventsStore.syncGoogleAcountData.connected_gmail !==
      undefined
    ) {
      // setconnectgmailaccount(storeData.calenderEventsStore.connectedgmail);
      var gooleconnecttime =
        storeData.calenderEventsStore.syncGoogleAcountData
          .google_calendar_connect_time;
      gooleconnecttime = parseInt(
        storeData.calenderEventsStore.syncGoogleAcountData
          .google_calendar_connect_time
      );
      gooleconnecttime = moment(
        storeData.calenderEventsStore.syncGoogleAcountData
          .google_calendar_connect_time
      ).format("DD-MMM-YYYY");
      // setgooglecaledersynctime(storeData.calenderEventsStore.gooleconnecttime);
      setconnectgmail(
        "connected_gmail",
        storeData.calenderEventsStore.connectedgmail,
        { path: "/" }
      );
    }
    if (
      storeData.calenderEventsStore.errorMessage &&
      storeData.calenderEventsStore.errorMessage === "error"
    ) {
      // setTimeout(() => {
      //   if (!storeData.calenderEventsStore.errorApiHit) {
      //     dispatch(getPartnerCalenderEvents({ req: requestData.current, loadMore: false }))
      //   }
      //   dispatch(setEroorApiHit())
      // }, 1000);
    }

    // }, 10000);
  });

  useEffect(() => {
    const savedCookies = new Cookies();
    savedCookies.remove("SlotData", { path: "/" });
  }, []);

  // <---------------- On Book Now Submission ---------------->
  useEffect(() => {

    if (bookNowSubmissionState === 2) {
      ///////////////////////// Clear Middleware cookies ////////////////////////
      const savedCookies = new Cookies();
      savedCookies.remove("middleware", { path: "/" });


      dispatch(setBooknowSubmissionState());
      // savedCookies.remove("SlotData", { path: "/" });
      const cookieDataSet = {
        event_type: clickableData.event_type,
        locationPath: clickableData.locationPath,
        event_title: clickableData.event_title,
        bookingUserEmail: clickableData.bookingUserEmail,
        startdate: clickableData.startdate,
        startDateTime: clickableData.startDateTime,
        user_timezone: clickableData.user_timezone,
        endDateTime: clickableData.endDateTime,
        timespan: clickableData.timespan,
      };

      const bookingIdentity = {
        eventId: eventId,
        identifier: identifier,
      };

      if (leadID) {
        console.log("window.location", window.location.pathname, eventId);
        let obj = {
          env: 'prod',
          leadId: leadID,
          googleevent: eventId,
          identifier: identifier,
          start_date_format_unix: moment(clickableData.startDateTime).valueOf(),
          booking_time_unix: moment().valueOf()
        }
        if (window.location.pathname == '/discoverycall-for-sales-dev') {
          obj.env = 'dev'
        }
        sessionStorage.setItem("bpsalesData", JSON.stringify(obj));

      }

      cookies.set("SlotData", cookieDataSet, { path: "/" });
      cookies.set("BookingIdentity", bookingIdentity, { path: "/" });
      navigate("/thankyou/booking", { replace: true });
    }
  }, [bookNowSubmissionState]);

  // <---------------- On Re-Schedule Submission ---------------->
  useEffect(() => {

    if (rescheduleSubmissionState === 2) {
      ///////////////////////// Clear Middleware cookies ////////////////////////
      const savedCookies = new Cookies();
      savedCookies.remove("middleware", { path: "/" });

      dispatch(setrescheduleSubmissionStateReset());
      const cookieDataSet = {
        event_type: clickableData.event_type,
        locationPath: clickableData.locationPath,
        event_title: clickableData.event_title,
        bookingUserEmail: clickableData.bookingUserEmail,
        startdate: clickableData.startdate,
        startDateTime: clickableData.startDateTime,
        user_timezone: clickableData.user_timezone,
        endDateTime: clickableData.endDateTime,
        timespan: clickableData.timespan,
      };
      cookies.set("SlotData", cookieDataSet, { path: "/" });
      navigate("/thankyou/reschedule", { replace: true });
    }
  }, [rescheduleSubmissionState]);

  useEffect(() => {
    if (snakbaropen === true) {
      setTimeout(() => {
        setsnakbaropen(false);
        setSnackBarMsg("");
      }, 6000);
    }
  }, [snakbaropen]);

  const handleChange = async (event) => {
    let eventValue = await event.target.value;
    settimezone(eventValue);


    const timezone = selectTimeZone(eventValue);
    if (timezone) {
      window.localStorage.setItem("currenttimezone", timezone);
    }
    setConvertedTimezone(timezone);
  };
  // wark space
  const handleOpen = (evevntsData) => {
    let emailattendeedata;
    if (rescheduleFlag) {
      emailattendeedata = JSON.parse(JSON.stringify(attendeeemaildata));
      for (let loop in emailattendeedata) {
        emailattendeedata[loop].name = attendeeemaildata[loop].email;
        emailattendeedata[loop].attendeName = attendeeemaildata[loop].name;
      }
      preformdata = {
        name: "",
        shareholders: emailattendeedata,
      };
    }
    if (!searchParams.get("lead_id") && !searchParams.get("rep_id")) {
      setformArrayData(() => {
        const data = { ...preformdata };
        if (rescheduleFlag) {
          const emailAtendees = emailattendeedata.map((data) => ({
            name: data.email,
            attendeName: data.attendeName,
            default: true,
          }));
          data.shareholders = [...emailAtendees];
        } else if (evevntsData?.atten_email?.length > 0) {
          const emailAtendees = evevntsData.atten_email.map((data) => ({
            name: data.email,
            attendeName: data.name,
            default: true,
          }));
          data.shareholders = [...emailAtendees, ...data.shareholders];
        }
        return data;
      });
    }
    setclickableData(evevntsData);
    dispatch(setSlotSelected(evevntsData));
    setOpen(true);
  };

  const handleClose = () => {
    setAttendeeError(false);
    setOpen(false);
    dispatch(setSlotSelected());
  };

  const [openConflictData, setopenConflictData] = useState(false);
  const [conflictModalData, setConflictModalData] = useState(null);

  const handleAddShareholder = () => {
    const attendeeNameNotExists = formArrayData.shareholders.find(
      (data) => !data.attendeName
    );
    const attendeeEmailNotExists = formArrayData.shareholders.find(
      (data) => !data.name
    );
    if (attendeeNameNotExists) setAttendeeError(true);
    else if (attendeeEmailNotExists) setAttendeeError(true);
    else {
      let concatdata = {
        shareholders: formArrayData.shareholders.concat([
          { name: "", attendeName: "" },
        ]),
      };
      setformArrayData(concatdata);
      setAttendeeError(false);
    }
  };

  const handleShareholderNameChange = (idx) => (evt) => {
    const newShareholders = formArrayData.shareholders.map(
      (shareholder, sidx) => {
        if (idx !== sidx) return shareholder;
        return { ...shareholder, name: evt.target.value };
      }
    );
    setformArrayData({ shareholders: newShareholders });
    setAttendeeError(false);
  };

  const handleNotesChange = (event) => {
    notes.current = event.target.value;
  };

  const handleattendeeNameChange = (idx) => (evt) => {
    const newShareholders = formArrayData.shareholders.map(
      (shareholder, sidx) => {
        if (idx !== sidx) return shareholder;
        return { ...shareholder, attendeName: evt.target.value };
      }
    );
    setformArrayData({ shareholders: newShareholders });
    setAttendeeError(false);
  };

  const handleRemoveShareholder = (idx) => () => {
    setformArrayData({
      shareholders: formArrayData.shareholders.filter(
        (s, sidx) => idx !== sidx
      ),
    });
  };

  // <---------------- Handle Load More ---------------->
  const handleLoadMore = () => {
    if (rescheduleParamID) {
      requestData.current.condition["userid"] = rescheduleParamID;
    }
    requestData.current.skip =
      requestData.current.skip + requestData.current.limit;

    if (intakebiopathFlag == true) {
      biodatasetloadmore.current.skip =
        biodatasetloadmore.current.skip + biodatasetloadmore.current.limit;
      if (daterangeValue != undefined && daterangeValue[0] != null) {
        let start_date = daterangeValue[0].getTime();
        let end_date = daterangeValue[1].getTime();
        let nowtime = new Date().getTime();
        if (nowtime > start_date) {
          start_date = nowtime;
        }
        biodatasetloadmore.current.condition["start_date"] = start_date;
        biodatasetloadmore.current.condition["end_date"] =
          end_date + 24 * 3600 * 1000 - 1000;
      }
      dispatch(fetchCalenderIntegrationEvents(biodatasetloadmore.current));
    } else if (intakepecepathFlag == true) {
      pecedatasetloadmore.current.skip =
        pecedatasetloadmore.current.skip + pecedatasetloadmore.current.limit;
      if (daterangeValue != undefined && daterangeValue[0] != null) {
        let start_date = daterangeValue[0].getTime();
        let end_date = daterangeValue[1].getTime();
        let nowtime = new Date().getTime();
        if (nowtime > start_date) {
          start_date = nowtime;
        }
        pecedatasetloadmore.current.condition["start_date"] = start_date;
        pecedatasetloadmore.current.condition["end_date"] =
          end_date + 24 * 3600 * 1000 - 1000;
      }
      if (location.pathname.includes("627d0cdc0bbdabff170cd7a0")) {
        pecedatasetloadmore.current.condition["userid1"] =
          "627d0cdc0bbdabff170cd7a0";
      }
      dispatch(
        fetchCalenderPECEIntegrationEventsmore(pecedatasetloadmore.current)
      );
    } else {
      otherPathsFlag || reschedule_slots_endpoint
        ? dispatch(
          getPartnerCalenderEvents({
            req: requestData.current,
            loadMore: true,
          })
        )
        : dispatch(fetchCalenderEventsLoadMore(requestData.current));
    }
  };

  // <---------------- Book Now Form Submission ---------------->
  const booknowformsubmit = () => {


    if (devineFlag) {
      let errorObject = {};

      if (!user?.full_name) {
        errorObject.full_name = "Name is Required";
      }
      if (!user?.city) {
        errorObject.city = "City is Required";
      }

      if (!user?.dob) {
        errorObject.dob = "DOB is Required";
      }

      if (!user?.time_of_birth) {
        errorObject.time_of_birth = "Time of Birth is Required";
      }


      if (!user?.phone) {
        errorObject.phone = "Contact Number is Required";
      } else if (user.phone.toString().length != 14) {
        errorObject.phone = "* Contact Number must be 10 digits";
      }



      if (user.Product_Deck_name == 'Weekly Transits') {
        if (!user?.selected_week) {
          errorObject.selected_week = "select week is Required";
        }
      }


      if (user.Product_Deck_name == 'Monthly Transits + Lunar Return') {
        if (!user?.selected_month) {
          errorObject.selected_month = "select Month is Required";
        }

      }


      if ((user.Product_Deck_name == 'Romantic Relationships') || (user.Product_Deck_name == 'Friendship Relationships') || (user.Product_Deck_name == 'Business Relationship')) {
        if (!user?.partner_dob) {
          errorObject.partner_dob = "Partner Date of Birth is Required";
        }

        if (!user?.partner_time_of_birth) {
          errorObject.partner_time_of_birth = "Partner Time of Birth is Required";
        }

        if (!user?.partner_city) {
          errorObject.partner_city = "Partner City is Required";
        }
      }

      if (user.Product_Deck_name == 'Predictive Event (Horary)') {
        if (!user?.your_question) {
          errorObject.your_question = "Your question is Required";
        }
      }



      if (Object.keys(errorObject).length > 0) {
        setError(errorObject);
      }

      console.log("formdata------", errorObject);

    }


    const attendeeNameNotExists = formArrayData.shareholders.find(
      (data) => !data.attendeName
    );
    const attendeeEmailNotExists = formArrayData.shareholders.find(
      (data) => !data.name
    );

    let errormail = false;

    formArrayData.shareholders.map((item) => {
      let mailcheck = chekValidEmail(item.name);
      if (!mailcheck) {
        errormail = true;
        return;
      }
    });

    if (
      errormail ||
      attendeeNameNotExists ||
      attendeeEmailNotExists ||
      !notes.current ||
      notes.current.length.current < 2
    ) {
      setAttendeeError(true);
      return;
    }

    let attendeeemaildataset = [];
    for (let i in formArrayData.shareholders) {
      if (
        (formArrayData.shareholders[i].name !== null) &
        (formArrayData.shareholders[i].name !== "")
      ) {
        attendeeemaildataset.push({
          name: formArrayData.shareholders[i].attendeName,
          email: formArrayData.shareholders[i].name,
        });
      }
    }

    if (fromAspirePartnersWithJamesFlag === true) {
      attendeeemaildataset.push({
        name: "James",
        email: "james@automagicmedia.com",
      });
    }

    if (
      (!clickableData.atten_email && attendeeemaildataset.length == 0) ||
      (clickableData.atten_email &&
        attendeeemaildataset.length <= clickableData.atten_email.length)
    ) {
      setAttendeeError(true);
      return;
    }

    let formdata = {};
    formdata = JSON.parse(JSON.stringify(clickableData));

    if (requestData.current.condition.userid !== undefined)
      formdata.condition = requestData.current.condition;
    else formdata.condition = { userid: formdata.userid };

    delete formdata._id;
    formdata.atten_email = attendeeemaildataset;
    formdata.notes = notes.current;
    if (rescheduleFlag) formdata.identifier = reschedulePath.params.identifier;

    formdata.locationPath = location.pathname.replace("/", "");
    formdata.user_timezone = convertedTimezone;

    if (leadID) {
      formdata.parentProjectLeadId = leadID;
    }
    if (urlEmail) {
      formdata.paramOrgEmail = urlEmail;
    }

    setclickableData(formdata);

    formdata = { ...formdata, for_betollc: true };

    if (eventHeader && eventHeader.aspireFlag === true) {
      formdata = { ...formdata, event_type: 12, for_betollc: true };
    }
    Object.preventExtensions(formdata);





    if (devineFlag) {
      formdata = { ...formdata, userAdditionalData: user, webisteFlag: 'divine' };

    }

    console.log("formdata------------", JSON.stringify(formdata));

    if (rescheduleFlag) dispatch(rescheduleevents(formdata));
    else dispatch(booknowevents(formdata));

    window.localStorage.removeItem("selectedSlot");
    window.localStorage.removeItem("fetchIdentifierData");
  };

  // <---------------- Reset Function ---------------->
  const resetFunction = () => {
    settimezone(currenttimezonename);
    setdaterangeValue([null, null]);
    if (rescheduleParamID)
      requestData.current.condition["userid"] = rescheduleParamID;

    delete requestData.current.condition["start_datetime_unix"];
    delete requestData.current.condition["endtimeunixval"];

    requestData.current.skip = 0;
    if (intakebiopathFlag == true) {
      var reqestdataset = {
        source: "events_eventdayarr_view",
        condition: {
          userid1: "627d0d3e0bbdabff170cd7a1",
          userid2: "627d0cdc0bbdabff170cd7a0",
        },
        limit: 100,
        skip: 0,
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
      };
      dispatch(fetchCalenderIntegrationEvents(reqestdataset));
    } else if (intakepecepathFlag == true) {
      if (!location.pathname.includes("627d0cdc0bbdabff170cd7a0")) {
        var reqestdataset = {
          source: "events_eventdayarr_view",
          condition: {
            userid1: "627d0d3e0bbdabff170cd7a1",
          },
          limit: 100,
          skip: 0,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
        };
        dispatch(fetchCalenderPECEIntegrationEvents(reqestdataset));
      } else {
        var reqestdataset = {
          source: "events_eventdayarr_view",
          condition: {
            userid: "627d0cdc0bbdabff170cd7a0",
            event_type: 4,
          },
          limit: 100,
          skip: 0,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
        };
        otherPathsFlag
          ? dispatch(
            getPartnerCalenderEvents({ req: reqestdataset, loadMore: true })
          )
          : dispatch(fetchCalenderEventsLoadMore(reqestdataset));
      }
    } else {
      otherPathsFlag
        ? dispatch(
          getPartnerCalenderEvents({
            req: requestData.current,
            loadMore: false,
          })
        )
        : dispatch(fetchCalenderEvents(requestData.current));
    }
  };

  // <---------------- Search Events ---------------->
  const searchFunction = () => {
    if (daterangeValue != undefined && daterangeValue[0] != null) {
      requestData.current.skip = 0;
      let start_date = daterangeValue[0].getTime();
      let end_date = daterangeValue[1].getTime();
      let nowtime = new Date().getTime();
      if (nowtime > start_date) start_date = nowtime;

      requestData.current.condition["start_datetime_unix"] = {
        $gte: start_date,
      };
      requestData.current.condition["endtimeunixval"] = {
        $lte: end_date + 24 * 3600 * 1000 - 1000,
      };
    }

    if (rescheduleParamID)
      requestData.current.condition["userid"] = rescheduleParamID;

    if (daterangeValue !== undefined && daterangeValue[0] != null) {
      if (intakebiopathFlag == true) {
        if (!location.pathname.includes("627d0cdc0bbdabff170cd7a0")) {

          var reqestdataset = {
            source: "events_eventdayarr_view",
            condition: {
              userid1: "627d0d3e0bbdabff170cd7a1",
              userid2: "627d0cdc0bbdabff170cd7a0",
            },
            limit: 100,
            skip: 0,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
          };
          let start_date = daterangeValue[0].getTime();
          let end_date = daterangeValue[1].getTime();
          let nowtime = new Date().getTime();
          if (nowtime > start_date) start_date = nowtime;

          requestData.current.condition["start_datetime_unix"] = {
            $gte: start_date,
          };
          requestData.current.condition["endtimeunixval"] = {
            $lte: end_date + 24 * 3600 * 1000 - 1000,
          };
          dispatch(fetchCalenderIntegrationEvents(reqestdataset));
        } else {
          var reqestdataset = {
            source: "events_eventdayarr_view",
            condition: {
              userid: "627d0cdc0bbdabff170cd7a0",
              event_type: 4,
            },
            limit: 100,
            skip: 0,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
          };
          let start_date = daterangeValue[0].getTime();
          let end_date = daterangeValue[1].getTime();
          let nowtime = new Date().getTime();
          if (nowtime > start_date) start_date = nowtime;

          requestData.current.condition["start_datetime_unix"] = {
            $gte: start_date,
          };
          requestData.current.condition["endtimeunixval"] = {
            $lte: end_date + 24 * 3600 * 1000 - 1000,
          };

          otherPathsFlag
            ? dispatch(
              getPartnerCalenderEvents({ req: reqestdataset, loadMore: true })
            )
            : dispatch(fetchCalenderEventsLoadMore(reqestdataset));
        }
      } else if (intakepecepathFlag == true) {
        if (!location.pathname.includes("627d0cdc0bbdabff170cd7a0")) {
          var reqestdataset = {
            source: "events_eventdayarr_view",
            condition: {
              userid1: "627d0d3e0bbdabff170cd7a1",
            },
            limit: 100,
            skip: 0,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
          };
          if (daterangeValue != undefined && daterangeValue[0] != null) {
            let start_date = daterangeValue[0].getTime();
            let end_date = daterangeValue[1].getTime();
            let nowtime = new Date().getTime();
            if (nowtime > start_date) start_date = nowtime;

            requestData.current.condition["start_datetime_unix"] = {
              $gte: start_date,
            };
            requestData.current.condition["endtimeunixval"] = {
              $lte: end_date + 24 * 3600 * 1000 - 1000,
            };
          }
          dispatch(fetchCalenderPECEIntegrationEvents(reqestdataset));
        } else {
          var reqestdataset = {
            source: "events_eventdayarr_view",
            condition: {
              userid: "627d0cdc0bbdabff170cd7a0",
              event_type: 4,
            },
            limit: 100,
            skip: 0,
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
          };
          if (daterangeValue != undefined && daterangeValue[0] != null) {
            let start_date = daterangeValue[0].getTime();
            let end_date = daterangeValue[1].getTime();
            let nowtime = new Date().getTime();
            if (nowtime > start_date) start_date = nowtime;

            requestData.current.condition["start_datetime_unix"] = {
              $gte: start_date,
            };
            requestData.current.condition["endtimeunixval"] = {
              $lte: end_date + 24 * 3600 * 1000 - 1000,
            };
          }
          otherPathsFlag
            ? dispatch(
              getPartnerCalenderEvents({
                req: reqestdataset,
                loadMore: false,
              })
            )
            : dispatch(fetchCalenderEventsLoadMorenoconcat(reqestdataset));
        }
      } else {
        otherPathsFlag
          ? dispatch(
            getPartnerCalenderEvents({
              req: requestData.current,
              loadMore: false,
              searchHit: true,
            })
          )
          : dispatch(fetchCalenderEvents(requestData.current));
      }
    }
  };

  const closeModal = () => {
    setAttendeeError(false);
    setOpen(false);
  };

  useEffect(() => {
    let body = {};
    if (searchParams.get("lead_id")) {
      body["lead_id"] = searchParams.get("lead_id");
    }
    if (searchParams.get("rep_id")) {
      body["rep_id"] = searchParams.get("rep_id");
    }

    if (body.lead_id || body.rep_id) {
      dispatch(fetchPramanIdData(body));
      dispatch(FetchDetails(body));
    }
  }, [searchParams]);

  useEffect(() => {
    if (lead_rep_data) {
      Object.keys(lead_rep_data).map((item, index) => {
        if (item === "rep_data") {
          formArrayData.shareholders[0]["name"] = lead_rep_data[item].rep_email;
          formArrayData.shareholders[0]["attendeName"] =
            lead_rep_data[item].rep_name;
        } else if (!formArrayData.shareholders[1] && item === "lead_data") {
          formArrayData.shareholders.push({
            ["name"]: lead_rep_data[item].lead_email,
            ["attendeName"]: lead_rep_data[item].lead_name,
          });
        }
      });
    }
  }, [lead_rep_data]);

  useEffect(() => {
    if (Object.keys(lead_rep_details).length > 0) {
      dispatch(saveRepLeadData(lead_rep_details));
    }
  }, [lead_rep_details]);

  const handelEmailSearch = (e) => {
    let mail = e.target.value;
    if (searchParams.get("lead_id")) {
      dispatch(FetchDetails({ lead_id: searchParams.get("lead_id") }));
    } else if (mail) {
      dispatch(FetchDetails({ email: mail }));
    }
  };

  useEffect(() => {
    if (searchParams.get("source") && betoparadiseopertunityes.length > 0) {
      let needdata = betoparadiseopertunityes.filter((item) => {
        if (item._ids.includes(searchParams.get("source"))) {
          return item;
        }
      });
      setEventHeader(needdata[0]);
    }
  }, [searchParams.get("source")]);

  useEffect(() => {
    if (proudctID && betoparadiseopertunityes.length > 0) {
      let needdata = betoparadiseopertunityes.filter((item) => {
        if (item._ids.includes(proudctID)) {
          return item;
        }
      });
      setEventHeader(needdata[0]);
    }
  }, [proudctID]);

  const chekValidEmail = (emailString) => {
    let pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(emailString);
  };

  useEffect(() => {
    if (reRunCalenderFlage) {
      dispatch(fetchCalenderEvents(requestData.current));
    }
  }, [reRunCalenderFlage]);





  return (
    <>
      {cancelslotFlag === true ? (
        <div className={`${devineFlag == true ? "mainContentWrapper form divineloader divine_slots_booking_page_main" : "mainContentWrapper form"} `}>

          {biopathFlag == true && (
            <Helmet>
              <title>BioEnergetics discovery webcast</title>
              <meta
                name="description"
                content="Book your discovery call for BioEnergetics platform with Beto Paredes by choosing slots and adding notes for him."
                data-react-helmet="true"
              ></meta>
            </Helmet>
          )}
          {pecepathFlag == true && (
            <Helmet>
              <title>PECE portal discovery webcast</title>
              <meta
                name="description"
                content="Book your discovery call for PECE™ with Michael White by choosing slots and adding notes for him."
                data-react-helmet="true"
              ></meta>
            </Helmet>
          )}
          {betopersonalpathFlag == true && (
            <Helmet>
              <title>Appointment with Beto Paredes</title>
              <meta
                name="description"
                content="Book your appointment with Beto Paredes."
                data-react-helmet="true"
              ></meta>
            </Helmet>
          )}

          {/* bio banner section */}
          {biopathFlag == true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg ">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span>
                      <img src="https://all-frontend-assets.s3.amazonaws.com/calendar-management/BioEnergetics_logo.png" />
                    </span>
                  </div>
                  <h2>
                    BOOK YOUR DISCOVERY WEB CAST WITH BETO PAREDES FOR THE
                    BIOENERGETICS PLATFORM
                    {/* <p>BOOK YOUR DISCOVERY WEB CAST WITH BETO PAREDES FOR THE BIOENERGETICS PLATFORM.</p> */}
                  </h2>
                </div>
              </div>
            </div>
          )}
          {aspirePartnersFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/blueCoastLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    <span>
                      <img
                        className="logo2"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes from Access Health and
                    Underpayment Services{" "}
                  </h2>
                </div>
              </div>
            </div>
          )}
          {aspirePartnersFlagJeannette === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://s3.amazonaws.com/calendar.peceportal.com/assets/access_health_medial_underpayments_logo.png"
                        alt="as_betologo"
                      />
                    </span>

                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Jeannette Skinner
                  </h2>
                </div>
              </div>
            </div>
          )}

          {fromAspirePartnersWithJamesFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/blueCoastLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    <span>
                      <img
                        className="logo2"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes from Access Health and
                    Underpayment Services{" "}
                  </h2>
                </div>
              </div>
            </div>
          )}

          {meetingWithDivineAdminFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://d37pbainmkhx6o.cloudfront.net/transcendentpagan/assets/images/logobanner.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Admin Divine Infinite Being{" "}
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* From Aspire Partners banner section */}
          {fromAspirePartnersFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://aspirepartners.com/wp-content/uploads/2022/01/aspire-main-logo_email-header.jpg"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes from Aspire Partners
                  </h2>
                </div>
              </div>
            </div>
          )}

          {bpsaleslucas === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/login/login_logo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Lucas Campbell from Betoparedes Sales
                    Backoffice
                  </h2>
                </div>
              </div>
            </div>
          )}

          {bpsalesNathan === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/login/login_logo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Nathan Campbell from Betoparedes Sales
                    Backoffice
                  </h2>
                </div>
              </div>
            </div>
          )}

          {bpsalesDaniel === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/login/login_logo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Daniel Gourley from Betoparedes Sales
                    Backoffice
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/*  Meeting set with Beto Paredes to review your Aspire Pharmacy Business banner section */}
          {fromReviewAspirePharmacyFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://aspirepartners.com/wp-content/uploads/2022/01/aspire-main-logo_email-header.jpg"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes to review Pharmacy Business
                    relationships
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Meeting set with Beto Paredes to review your Bluecoast Pharmacy Business banner section */}
          {fromReviewBluecoastPharmacyFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/blueCoastLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes to review Pharmacy Business
                    relationships
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Meeting set with Beto Paredes to review your Aspire Underpayment Lead opportunities banner section */}
          {fromReviewAspireUnderpaymentsFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://aspirepartners.com/wp-content/uploads/2022/01/aspire-main-logo_email-header.jpg"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes to review Underpayment Lead
                    opportunities
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Meeting set with Beto Paredes to review your Bluecoast Underpayment Lead opportunities banner section */}
          {fromReviewBluecoastUnderpaymentsFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/blueCoastLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes to review Underpayment Lead
                    opportunities
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Review of the Access Health Underpayments program with Beto Paredes banner section */}
          {fromReviewAccessHealthUnderpaymentsFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://s3.amazonaws.com/calendar.peceportal.com/assets/access_health_medial_underpayments_logo.png"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Review of the Access Health Underpayments program with Beto
                    Paredes
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Review of the Access Health Free Pharmacy program for larger organizations banner section */}
          {fromReviewAccessHealthPharmacyFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Review of the Access Health Free Pharmacy program for larger
                    organizations
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Underpayment Discovery Meeting with Lucas */}
          {underpaymentDiscoverWithLucas === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Underpayment Discovery Meeting with Lucas
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Practice2Lab Meeting with Lucas */}
          {practice2LabMeetingWithLucas === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_next/navbar/Nav_logo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span><img className="logo2" src='https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp' alt="as_betologo" /></span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Practice2Lab Meeting with Lucas
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* practicepresence-cancer Meeting with Lucas */}
          {practicepresencecancer === true && searchParams.get("source") && (
            <div
              className="ContentWrapper BioEnergetics_banner1"
              style={{ background: `${eventHeader?.theme?.background}` }}
            >
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      {" "}
                      {searchParams.get("source") ? (
                        <img
                          className="logo1"
                          src={eventHeader.logs}
                          alt="as_betologo"
                        />
                      ) : (
                        <p>Practicepresence Cancer</p>
                      )}
                    </span>
                  </div>
                  <h2
                    className="aspireheader"
                    style={{ color: `${eventHeader?.theme?.color}` }}
                  >
                    {searchParams.get("source")
                      ? eventHeader.text
                      : "Practice Presence Cancer Slots Booking"}
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* Aspire Partners banner section */}
          {partnerWithJohnFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/blueCoastLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    <span>
                      <img
                        className="logo2"
                        src="https://all-frontend-assets.s3.amazonaws.com/aspireLeadFire/landing-page9/landingpage9_newLogo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes from Access Health and
                    Underpayment Services{" "}
                  </h2>
                </div>
              </div>
            </div>
          )}
          {jaredFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="fryrightBg1">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://d37pbainmkhx6o.cloudfront.net/fryright_images/logo.png"
                        alt="as_betologo"
                      />
                    </span>

                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Jared Brague{" "}
                  </h2>
                </div>
              </div>
            </div>
          )}


          {biogenixFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="fryrightBg1">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.us-east-1.amazonaws.com/biogenix-ui/login_page/biogenix_login_page_logo.webp"
                        alt="Biogenix logo"
                      />
                    </span>

                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    {`Meeting with ${searchParams.get("org_name")}`}
                  </h2>
                </div>
              </div>
            </div>
          )}



          {(betoMedicalSingle === true || courtneyMedical == true || bryenMedical == true || betoMedicalCoutr == true) && (
            <div className="ContentWrapper BioEnergetics_banner1 courtneyForMedicalWrp">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span logo2span" >
                      <img
                        className="logo1" loading="lazy" 
                        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                        alt="as_betologo"
                      />
                    </span>
                    <span className="logo1span " >
                      <img
                        className="logo1" loading="lazy" 
                        src="https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"
                        alt="Clinical Speed"
                      />
                    </span>



                    {/* <span style={{ textAlign: 'center', marginBottom: '8px' }}>
                      <img
                        className="logo2 " style={{ width: '51%' }} loading="lazy"
                        src="https://dev.decisiondoc.com/assets/images/dicision_doc.png"
                        alt="as_betologo"
                      />
                    </span>
                    <span style={{ textAlign: 'center' }}>
                      <img
                        className="logo3" style={{ width: '51%' }} loading="lazy"
                        src="https://all-frontend-assets.s3.amazonaws.com/access_health_underpayment_ui_next/Stat-medical-centers/demo_img/rapid_response_nw_logo.webp"
                        alt="as_betologo"
                      />
                    </span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  {(betoMedicalSingle || betoMedicalCoutr) && <h2 className="aspireheader courtneyForMedicalHeaderWrp">
                    Meeting with Beto Paredes for Beto Paredes Family of Companies Medical Division{" "}
                  </h2>}
                  {courtneyMedical && <h2 className="aspireheader courtneyForMedicalHeaderWrp">
                    Meeting with Courtney Garner for the Beto Paredes Family of Companies Medical Division{" "}
                  </h2>}
                  {bryenMedical && <h2 className="aspireheader courtneyForMedicalHeaderWrp">
                    Meeting with Bryen Jayne for the Beto Paredes Family of Companies Medical Division{" "}
                  </h2>}

                </div>
              </div>
            </div>
          )}

          {decisionDocFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://dev.decisiondoc.com/assets/images/dicision_doc.png"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">
                    Meeting with Beto Paredes from Decision Doc
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* practice2lab  */}
          {practice2labFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/practice2lab_next/navbar/Nav_logo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">Meeting with Practice2lab</h2>
                </div>
              </div>
            </div>
          )}



          {betoMedicalCoutrClinical === true && (
            <div className="ContentWrapper BioEnergetics_banner1 courtneyForMedicalWrp">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo" >

                    <span className="logo1span logo2span">
                      <img
                        className="logo1" loading="lazy" 
                        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                        alt="as_betologo"
                      />
                    </span>
                    <span className="logo1span" >
                      <img
                        className="logo1" loading="lazy" 
                        src="https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"
                        alt="Clinical Speed"
                      />
                    </span>
                    {/* <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"
                        alt="as_betologo"
                      />
                    </span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader courtneyForMedicalHeaderWrp">Meeting with Beto Paredes for review of Clinical Speed Forensic Audits</h2>
                </div>
              </div>
            </div>
          )}

          {/* clinicalspeed  */}
          {clinicalspeedFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">Meeting with Clinical Speed</h2>
                </div>
              </div>
            </div>
          )}

          {/* underpayments  */}
          {underpaymentsFlag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      <img
                        className="logo1"
                        src="https://s3.amazonaws.com/calendar.peceportal.com/assets/access_health_medial_underpayments_logo.png"
                        alt="as_betologo"
                      />
                    </span>
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2 className="aspireheader">Meeting with Underpayments</h2>
                </div>
              </div>
            </div>
          )}

          {/* pece banner section */}
          {pecepathFlag == true && (
            <div className="ContentWrapper PECE_banner1">
              <div className="PECE_banner1_bg">
                <div className="banner_textblock">
                  {!location.pathname.includes("629f57f8ac8244773d9bc018") && (
                    <div className="banner_textblock_logo">
                      <span>
                        <img
                          src="https://all-frontend-assets.s3.amazonaws.com/calendar-management/PECE_logo.png"
                          alt="PECE_logo"
                        />
                      </span>
                    </div>
                  )}
                  {!location.pathname.includes("629f57f8ac8244773d9bc018") &&
                    !location.pathname.includes("632868d6dc35759c6f3afa8c") && (
                      <h2>Meeting With Nathan Campbell With PECE Platform</h2>
                    )}
                  {location.pathname.includes("629f57f8ac8244773d9bc018") && (
                    <h2>Book your event with Debasis Kar</h2>
                  )}

                  {location.pathname.includes("632868d6dc35759c6f3afa8c") && (
                    <h2>
                      PECE portal discovery webcast with Nadine Varela
                      <p>
                        Now you can book your discovery call for PECE™ with one
                        of our senior consultants Nadine Varela by choosing
                        slots and adding notes for the closer (senior).
                      </p>
                    </h2>
                  )}
                </div>
              </div>
            </div>
          )}

          {betov3flag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div
                className="BioEnergetics_banner1_bg"
                style={{ background: `${eventHeader?.theme?.background}` }}
              >
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      {" "}
                      {proudctID ? (
                        <img
                          className="logo1"
                          src={eventHeader?.logs?eventHeader?.logs:"https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"}
                          alt="as_betologo"
                        />
                      ) : (
                        <img
                          src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                          alt="Betoparedes"
                        />
                      )}
                    </span>{" "}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2
                    className="aspireheader"
                    style={{ color: `${eventHeader?.theme?.color}` }}
                  >
                    Book a Discovery call with Beto Paredes
                  </h2>
                </div>
              </div>
            </div>
          )}
          {croutnyFlagBackoffice === true && (
            <div className="ContentWrapper BioEnergetics_banner1 courtneyForMedicalWrp">
              <div
                className="BioEnergetics_banner1_bg"
               
              >
                <div className="banner_textblock">
                  <div className="banner_textblock_logo" >
                  <span className="logo1span logo2span">
                      <img
                        className="logo1" loading="lazy" 
                        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                        alt="as_betologo"
                      />
                    </span>
                   

                    <span className="logo1span" >
                      {" "}
                      {proudctID ? (
                        <img
                          className="logo1"
                          src={eventHeader?.logs?eventHeader.logs:"https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"}
                          alt="as_betologo"
                          // style={{ marginTop: '15px',borderRadius:"20px", background: "#fff", padding: "7px" }}
                        />
                      ) : (
                        <img
                         className="logo1"
                         loading="lazy"
                        //  style={{ marginTop: '15px',borderRadius:"20px", background: "#fff", padding: "7px" }}
                          src="https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"
                          alt="Betoparedes"
                          
                        />
                      )}
                    </span>{" "}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2
                    className="aspireheader courtneyForMedicalHeaderWrp"
                    style={{ color: `${eventHeader?.theme?.color}` }}
                  >
                    Book a Discovery call with Courtney Garner
                  </h2>
                </div>
              </div>
            </div>
          )}




          {nathanV3flag === true && (
            <div className="ContentWrapper BioEnergetics_banner1">
              <div
                className="BioEnergetics_banner1_bg"
                style={{ background: `${eventHeader?.theme?.background}` }}
              >
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      {" "}
                      {proudctID ? (
                        <img
                          className="logo1"
                          src={eventHeader.logs}
                          alt="as_betologo"
                        />
                      ) : (
                        <img
                          src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                          alt="Betoparedes"
                        />
                      )}
                    </span>{" "}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2
                    className="aspireheader"
                    style={{ color: `${eventHeader?.theme?.color}` }}
                  >
                    Book a Gameplan Call with Beto Paredes
                  </h2>
                </div>
              </div>
            </div>
          )}

          {croutnyFlagBackofficeGameplan === true && (
            <div className="ContentWrapper BioEnergetics_banner1 courtneyForMedicalWrp">
              <div
                className="BioEnergetics_banner1_bg"
                
              >
                <div className="banner_textblock">
                  <div className="banner_textblock_logo"  >
                  <span className="logo1span logo2span">
                      <img
                        // className="logo1" loading="lazy" style={{borderRadius:"20px", background: "#fff", padding: "19px" }}
                        src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                        alt="as_betologo"
                      />
                    </span>
                   

                    <span className="logo1span" >
                      {" "}
                      {proudctID ? (
                        <img
                          className="logo1"
                          src={eventHeader?.logs?eventHeader.logs:"https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"}
                          alt="as_betologo"
                          // style={{ marginTop: '15px',borderRadius:"20px", background: "#fff", padding: "7px", }}
                        />
                      ) : (
                        <img
                        className="logo1" loading="lazy" 
                          src="https://all-frontend-assets.s3.amazonaws.com/clinical_speed_next_ui/Header/clinical_logo.webp"
                          alt="Betoparedes"
                        />
                      )}
                    </span>{" "}
                    {/* <span className="logo1span">
                      {" "}
                      {proudctID ? (
                        <img
                          className="logo1"
                          src={eventHeader.logs}
                          alt="as_betologo"
                        />
                      ) : (
                        <img
                          src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                          alt="Betoparedes"
                        />
                      )}
                    </span>{" "} */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2
                    className="aspireheader courtneyForMedicalHeaderWrp"
                    style={{ color: `${eventHeader?.theme?.color}` }}
                  >
                    Book a Gameplan Call with Courtney Garner
                  </h2>
                </div>
              </div>
            </div>
          )}

          {/* ------------------------------------------------rescheduleFlag-------------------------------------------------- */}

          {/* integration meeting bio */}
          {intakebiopathFlag == true && (
            <div className="ContentWrapper PECE_banner1">
              <div className="PECE_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span>
                      <img src="https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/BioEnergetics_banner2.png" />
                    </span>
                  </div>
                  <h2>
                    BioEnergetics Integration Meeting
                    <p>
                      You can schedule a call by chosing slots and adding notes.
                    </p>
                  </h2>
                </div>
              </div>
            </div>
          )}
          {intakepecepathFlag == true && (
            <div className="ContentWrapper PECE_banner1">
              <div className="PECE_banner1_bg">
                {!location.pathname.includes("627d0cdc0bbdabff170cd7a0") && (
                  <div className="banner_textblock">
                    <div className="banner_textblock_logo">
                      <span>
                        <img src="https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/PECE_banner1.png" />
                      </span>
                    </div>
                    <h2>
                      PECE™ Integration Meeting
                      <p>
                        You can schedule a call by chosing slots and adding
                        notes.
                      </p>
                    </h2>
                  </div>
                )}
                {location.pathname.includes("627d0cdc0bbdabff170cd7a0") && (
                  <div className="banner_textblock">
                    <div className="banner_textblock_logo">
                      <span>
                        <img src="https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/PECE_banner1.png" />
                      </span>
                    </div>
                    <h2>
                      PECE™ Integration Meeting With Jared
                      <p>
                        You can schedule a call by chosing slots and adding
                        notes.
                      </p>
                    </h2>
                  </div>
                )}
              </div>
            </div>
          )}
          {onePintCarePrecticePrecenceFlage && (
            <div className="ContentWrapper PECE_banner1">
              <div className="prectice_presence_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span>
                      <img
                        src="https://all-frontend-assets.s3.amazonaws.com/on-pointe-care/onpointecare_logo_img.webp"
                        alt="P"
                      />
                    </span>
                  </div>

                  <h2 className=" prectice_presece_header_text">
                    Prectice Presence Meeting
                    <p>
                      {" "}
                      You can schedule a call by chosing slots and adding notes.{" "}
                    </p>
                  </h2>
                </div>
              </div>
            </div>
          )}
          {betopersonalpathFlag == true && (
            //  <img src="https://all-frontend-assets.s3.amazonaws.com/calendermanagementreact/assets/images/beto_banner.png" />
            <div className="ContentWrapper BioEnergetics_banner1">
              <div
                className="BioEnergetics_banner1_bg"
                style={{ background: `${eventHeader?.theme?.background}` }}
              >
                <div className="banner_textblock">
                  <div className="banner_textblock_logo">
                    <span className="logo1span">
                      {" "}
                      {proudctID ? (
                        <img
                          className="logo1"
                          src={eventHeader.logs}
                          alt="as_betologo"
                        />
                      ) : (
                        <img
                          src="https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/home/Beto_Logo.png"
                          alt="Betoparedes"
                        />
                      )}
                    </span>{" "}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <h2
                    className="aspireheader"
                    style={{ color: `${eventHeader?.theme?.color}` }}
                  >
                    Book a Discovery call with Beto Paredes
                  </h2>
                </div>
              </div>
            </div>
          )}



          {devineFlag == true && (

            <div className="BioEnergetics_banner1 divine_sec">
              <div className="BioEnergetics_banner1_bg">
                <div className="banner_textblock">
                  <div className="banner_textblock_logo divine_logo">
                    {/* <span className="logo1span"> */}
                    <img
                      className="logo1"
                      src="https://all-frontend-assets.s3.us-east-1.amazonaws.com/transcendentpagan/assets/images/divine_slots_booking_sec_logo.webp"
                      alt="as_betologo"
                    />
                    {/* </span> */}
                    {/* <span className="logo_content">in association with Aspire Partners</span> */}
                  </div>
                  <div className="BioEnergetics_bnr_heading_sec">
                    <h2 className="aspireheader">Appointment with Beto Paredes for Astrology / Tarot Session in SCHOOL OF OUR DIVINE INFINITE BEING</h2>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="ContentWrapper">
            {!fromReviewBluecoastUnderpaymentsFlag &&
              !aspirePartnersFlag &&
              !fromAspirePartnersFlag &&
              !fromAspirePartnersWithJamesFlag &&
              !fromReviewAspirePharmacyFlag &&
              !fromReviewBluecoastPharmacyFlag &&
              !fromReviewAspireUnderpaymentsFlag &&
              !fromReviewAccessHealthUnderpaymentsFlag &&
              !fromReviewAccessHealthPharmacyFlag ? (
              <div className="Formdiv">
                {/* {/ Login Form Start Here /} */}
                <div className="FormBlock" ref={rootElement}>
                  {/* {/ connected email section /} */}
                  {pathFlag === true && (
                    <div>
                      <div className="buttonsetToolbar">
                        <Button variant="contained">
                          <Link to="/calendar-management-createEvent">
                            Create New Availability
                          </Link>
                        </Button>
                        <Button variant="contained">
                          <Link to="/calendar-management-event-listing">
                            Availability List
                          </Link>
                        </Button>
                        <Button
                          href={`https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar?id=${userCalenderID}&url=${(window.location.href).replace(/\/$/, "")}`}
                          variant="contained"
                        >
                          Update Google Account
                        </Button>
                        <Button variant="contained">
                          <Link to="/calendar-management-booked-slots">
                            Booked Slot
                          </Link>
                        </Button>

                        {devflag && (
                          <Button variant="contained">
                            <Link to="/events-slot-summery">Event Summary</Link>
                          </Button>
                        )}

                        <Button variant="contained">
                          <Link to="/calendar-management-cron-list">
                            Cron Summery
                          </Link>
                        </Button>

                        {currentUser ? (
                          <Button variant="contained" id="button">
                            <Link to="/calendar-management-manageUser">
                              Manage User
                            </Link>
                          </Button>
                        ) : (
                          ""
                        )}
                        {devflag && (
                          <>
                            <Button
                              variant="contained"
                              onClick={(event) =>
                                setAnchorEl(event.currentTarget)
                              }
                            >
                              Developer Info
                            </Button>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openCalendarInfo}
                              onClose={() => handleCalendarInfoClose()}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                onClick={() =>
                                  handleCalendarInfoClose(
                                    "developer-info/pece-dev"
                                  )
                                }
                              >
                                Pece Developement
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleCalendarInfoClose(
                                    "developer-info/pece-live"
                                  )
                                }
                              >
                                Pece Live
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleCalendarInfoClose(
                                    "developer-info/calendar-management"
                                  )
                                }
                              >
                                Calendar Management
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleCalendarInfoClose(
                                    "developer-info/aspire-dev"
                                  )
                                }
                              >
                                Aspire Dev
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleCalendarInfoClose(
                                    "developer-info/aspire-prod"
                                  )
                                }
                              >
                                Aspire Prod
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {/* wark space */}
                  <Modal
                    className={devineFlag ? 'modalblock bookingModal user_from_modal divineModal' : "modalblock bookingModal user_from_modal"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Button
                        type="button"
                        className="closeModalBtn"
                        onClick={closeModal}
                      >
                        x
                      </Button>
                      <Box className="modalBox">
                        {/* <p> HIIi :{JSON.stringify(clickableData)}</p>  */}
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          {clickableData.event_title}
                        </Typography>


                        <div id="modal-modal-description" sx={{ mt: 2 }}>
                          <div className="calender_block bookNowModal">
                            <p>
                              <span>Date: </span>
                              {timeConverter({
                                time: new Date(clickableData.startDateTime),
                                format: "dddd, MMMM D, YYYY",
                                timezone: convertedTimezone,
                              })}
                            </p>
                            {/* {clickableData.startdate} */}
                            <p>
                              <span>Time: </span>
                              {timeConverter({
                                time: new Date(clickableData.startDateTime),
                                format: "h:mm A",
                                timezone: convertedTimezone,
                              })}{" "}
                              -{" "}
                              {timeConverter({
                                time: new Date(clickableData.endDateTime),
                                format: "h:mm A",
                                timezone: convertedTimezone,
                              })}
                            </p>
                            {/* {clickableData.slot} - {clickableData.slot_end_time} */}
                            <p>
                              <span>Timezone: </span>
                              {
                                abbrs[
                                timeConverter({
                                  time: new Date(clickableData.endDateTime),
                                  format: "zz",
                                  timezone: convertedTimezone,
                                })
                                ]
                              }
                            </p>
                            {/* {clickableData.timezoneName} */}
                          </div>
                        </div>
                        {/* add attendee email start */}
                        {/* <form > */}
                        <h4>Add Attendee</h4>
                        <Card sx={{ minWidth: 275 }}>
                          <CardContent>
                            <Typography sx={{ fontSize: 12 }} color="#005684">
                              <strong>Note</strong>: Fill in Your Name and Your
                              Email below prior to adding Attendee(s). Multiple
                              Attendees can be added by clicking ADD ATTENDEE
                              for each attendee addition.
                              <br />
                              {clickableData?.username ==
                                "Jeannette Skinner" && (
                                  <>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        color: "#005684",
                                      }}
                                    >
                                      IMPORTANT: When scheduling your Discovery
                                      Call with Jeannette, you must enter the
                                      facility name, the attendees' names, and
                                      their titles in the Notes section.
                                    </p>
                                  </>
                                )}
                            </Typography>
                          </CardContent>
                        </Card>

                        {/* wark space  */}
                        {formArrayData.shareholders.map((shareholder, idx) => (
                          <div className="shareholder addemailbox" key={idx}>
                            <input
                              className="addemail"
                              type="text"
                              placeholder={
                                idx == 0 ? "Your name" : "Attendee Name"
                              }
                              value={shareholder.attendeName}
                              disabled={Boolean(shareholder.default)}
                              onChange={handleattendeeNameChange(idx)}
                            />
                            <input
                              className="addemail"
                              type="text"
                              placeholder={
                                idx == 0 ? "Your email" : "Attendee Email"
                              }
                              value={shareholder.name}
                              disabled={Boolean(shareholder.default)}
                              onChange={handleShareholderNameChange(idx)}
                              onBlur={(e) => handelEmailSearch(e)}
                            />
                            {idx >= 1 && (
                              <button
                                disabled={Boolean(shareholder.default)}
                                type="button"
                                onClick={handleRemoveShareholder(idx)}
                                className="small addemailBTN"
                              >
                                -
                              </button>
                            )}
                            {attendeeError &&
                              (!shareholder.name ||
                                !shareholder.attendeName) && (
                                <p className="warn">
                                  Attendee email and name is mandatory to book
                                  an event
                                </p>
                              )}
                            {attendeeError &&
                              shareholder.name &&
                              !chekValidEmail(shareholder.name) && (
                                <p className="warn">Invalid Email Address</p>
                              )}
                          </div>
                        ))}

                        {/* {attendeeError && <p className="warn">Attendee email and name is mandatory to book an event</p>} */}
                        <div className="modalSUBbtn_wrapper">
                          <Button
                            type="button"
                            onClick={handleAddShareholder}
                            className="small modalSUBbtn"
                            disabled={bookNowload}
                          >
                            Add Attendee
                          </Button>
                        </div>

                        {(location.pathname == "/divineinfinitebeing" || location.pathname == "/divineinfinitebeingtest") &&
                          <UserForm setUser={setUser} user={user} setError={setError} error={error} />
                        }




                        <div className="note_section_main">
                          <h4>Notes</h4>
                          <TextareaAutosize
                            minRows={4}
                            style={{
                              width: "100%",
                              border: "solid 1px",
                              padding: "5px",
                              resize: "none",
                            }}
                            onChange={(e) => handleNotesChange(e)}
                          />
                        </div>
                        {attendeeError &&
                          notes.current !== null &&
                          notes.current.length < 2 && (
                            <p className="warn">
                              Notes is mandatory to book an event
                            </p>
                          )}
                        {/* {clickableData} */}

                        {bookNowload && <LinearProgress />}
                        <div className="modalSUBbtn_wrapper">
                          <Button
                            className="modalSUBbtn"
                            href=""
                            variant="contained"
                            onClick={booknowformsubmit}
                            disabled={bookNowload}
                          >
                            Submit
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Modal>
                  {/* here is a timezone dropdown */}

                  {menuItems && menuItems.length > 0 && (
                    <div className="form_wrapper">
                      <div className="form_group timezonecls">
                        <InputLabel class={devineFlag == true ? 'divine_orange_cont' : ""} id="demo-simple-select-autowidth-label">
                          Choose Timezone
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={timezone}
                          onChange={handleChange}
                          autoWidth
                          label="Choose Timezone"
                        >
                          {menuItems.map((item, index) => (
                            <MenuItem key={index} value={item.timeZone}>
                              {item.name}
                            </MenuItem>
                          ))}
                          {/* <MenuItem
                            value={findOffset("Alaska Standard Time", false)}
                          >
                            Alaska Standard Time
                          </MenuItem>
                          <MenuItem value={"+05:30|Asia/Kolkata"}>
                            India Standard Time
                          </MenuItem>
                          <MenuItem value={"-08:00|America/Los_Angeles"}>
                            Pacific Standard Time
                          </MenuItem>
                          <MenuItem value={"-07:00|America/Phoenix"}>
                            Mountain Standard Time On DTS (GMT-07:00)
                          </MenuItem>
                          <MenuItem value={"-06:00|America/Chicago"}>
                            Central Standard Time
                          </MenuItem>
                          <MenuItem value={"-05:00|America/New_York"}>
                            Eastern Standard Time
                          </MenuItem>
                          <MenuItem value={"-10:00|Pacific/Honolulu"}>
                            Hawaii Standard Time
                          </MenuItem> */}
                        </Select>
                      </div>

                      <div className="daterangecls form_group courtneyForMedicalDateTimeWrp">
                        <InputLabel class={devineFlag == true ? 'divine_orange_cont' : ""} id="demo-simple-select-autowidth-label">
                          Filter By Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateRangePicker
                            startText="Start-Date"
                            endText="End-Date"
                            value={daterangeValue}
                            onChange={(newValue) => {
                              setdaterangeValue(newValue);
                            }}
                            minDate={new Date()}
                            onOpen={() => {
                              //////////////////////// Removing Missing License Key Message /////////////////////
                              setTimeout(() => {
                                if (
                                  document.querySelector("daterangecls") !=
                                  undefined &&
                                  document.querySelector("daterangecls")
                                    .style != undefined
                                )
                                  document.querySelector(
                                    "daterangecls"
                                  ).style.display = "none";

                                let e = document.getElementsByTagName("div");
                                for (let i = 0; i < e.length; i++) {
                                  let textval = e[i].innerText;
                                  if (
                                    textval.includes("Missing license key") &&
                                    textval.length < 30
                                  ) {
                                    e[i].style.display = "none";
                                  }
                                }
                                if (
                                  document.querySelector("daterangecls") !=
                                  undefined &&
                                  document.querySelector("daterangecls")
                                    .style != undefined
                                )
                                  document.querySelector(
                                    "daterangecls"
                                  ).style.display = "block";
                              }, 200);
                            }}
                            renderInput={(startProps, endProps) => (
                              <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                              </React.Fragment>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="two_btn_wrp">
                        <div className={devineFlag == true ? 'subBTN divinesubBTN' : "subBTN"}>
                          <Button
                            href=""
                            variant="contained"
                            onClick={searchFunction}
                          >
                            <SearchIcon />
                          </Button>
                        </div>
                        <div className={devineFlag == true ? 'subBTN divinesubBTN' : "subBTN"}>
                          <Button
                            href=""
                            variant="contained"
                            onClick={resetFunction}
                          >
                            <ReplayIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  <h6>
                    Showing{" "}
                    {Array.isArray(calenderEventsData) &&
                      calenderEventsData.length
                      ? calenderEventsData.length < 300 ? calenderEventsData.length : 300
                      : 0}{" "}
                    Records :
                  </h6>
                  {loaderFlag == true && calenderEventsData.length == 0 ? (
                    <Box sx={{ width: "100%", mt: "10px" }}>
                      <LinearProgress sx={{ height: "5px" }} />
                    </Box>
                  ) : (
                    ""
                  )}
                  <div className="calender_body formCls">
                    {calenderEventsData != undefined &&
                      calenderEventsData.length > 0 ? (
                      calenderEventsData.map((events, index) => {
                        if (index <= 300)
                          return (
                            // <div key={index} className="calender_block" >
                            <div key={index} className={((betoMedicalSingle === true || courtneyMedical == true || bryenMedical == true || betoMedicalCoutr == true) ? 'calender_block newColorSchemNew' : devineFlag == true ? 'calender_block divineColourSchem' : 'calender_block')} >
                              <h2
                                style={{
                                  background: `${eventHeader?.theme?.background}`,
                                  color: `${eventHeader?.theme?.color}`,
                                }}
                              >
                                {/* {`${events.event_title}${
                              searchParams.get("source")
                                ? " for " + events.event_title
                                : ""
                            } `} */}
                                {events.event_title}
                              </h2>
                              <p>
                                <span>Date: </span>
                                {timeConverter({
                                  time: new Date(events.startDateTime),
                                  format: "dddd, MMMM D, YYYY",
                                  timezone: convertedTimezone,
                                })}
                              </p>
                              <p>
                                <span>Time: </span>
                                {timeConverter({
                                  time: new Date(events.startDateTime),
                                  format: "h:mm A",
                                  timezone: convertedTimezone,
                                })}{" "}
                                -{" "}
                                {timeConverter({
                                  time: new Date(events.endDateTime),
                                  format: "h:mm A",
                                  timezone: convertedTimezone,
                                })}
                              </p>

                              <p>
                                <span>Timezone: </span>{" "}
                                {
                                  abbrs[
                                  timeConverter({
                                    time: new Date(events.endDateTime),
                                    format: "zz",
                                    timezone: convertedTimezone,
                                  })
                                  ]
                                }
                              </p>
                              {pathFlag === false && (
                                <Button
                                  className={devineFlag == true ? "slotBookBtn divineslotbookbtn" : "slotBookBtn"}
                                  href=""
                                  variant="contained"
                                  onClick={() => handleOpen(events)}
                                  style={{
                                    background: `${eventHeader?.theme?.buttons?.background}`,
                                    color: `${eventHeader?.theme?.buttons?.color}`,
                                  }}
                                >
                                  {rescheduleFlag ? "Re-Schedule" : "Book Now"}
                                  <KeyboardDoubleArrowRightIcon />
                                </Button>
                              )}

                              {conflictQueryParam && (
                                <Button
                                  className="slotBookBtn"
                                  href=""
                                  variant="contained"
                                  onClick={() => {

                                    setopenConflictData(true);
                                    setConflictModalData(events.conflict);
                                  }}
                                >
                                  {" "}
                                  View Conflict
                                </Button>
                              )}
                            </div>


                          );
                      })
                    ) : (
                      <>
                        {loaderFlag == true ? (
                          <h3>
                            <em>Fetching Available Slots!!! Please Wait...</em>
                          </h3>
                        ) : (
                          <h3>
                            <em>No Slots Found</em>
                          </h3>
                        )}
                      </>
                    )}
                  </div>
                  <div className="loadMoreSection">
                    {calenderEventsData.length != 0 &&
                      !location.pathname.includes("627d0cdc0bbdabff170cd7a0") ? (
                      <Button
                        variant="contained"
                        className="loadMore"
                        onClick={() => handleLoadMore()}
                        disabled={loaderFlag || noMoreSlots}
                        ref={inViewRef}
                      >
                        Load More
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>

                  {loaderFlag == true ? (
                    <Box sx={{ width: "100%", mt: "10px" }}>
                      <LinearProgress sx={{ height: "5px" }} />
                    </Box>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                {" "}
                <h3>
                  <a href="mailto:support@apirepartnersusa.com">
                    support@apirepartnersusa.com
                  </a>
                </h3>{" "}
                <p style={{ fontSize: "28px", fontWeight: "bold" }}>
                  Please contact this email for any support
                </p>
              </div>
            )}
          </div>

          <Modal
            className="modalblock bookingModal"
            open={openConflictData}
            onClose={() => {
              setopenConflictData(false);
              setConflictModalData(null);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Button
                type="button"
                className="closeModalBtn"
                onClick={() => {
                  setopenConflictData(false);
                  setConflictModalData(null);
                }}
              >
                x
              </Button>
              <Box className="modalBox">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Conflict Data
                </Typography>
                {conflictModalData && (
                  <div id="modal-modal-description" sx={{ mt: 2 }}>
                    {(conflictModalData.summary ||
                      conflictModalData.subject) && (
                        <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                          <p>
                            <spam
                              style={{ fontWeight: "bold", marginRight: "10px" }}
                            >
                              {" "}
                              Subject:{" "}
                            </spam>{" "}
                            {conflictModalData.summary ||
                              conflictModalData.subject}
                          </p>
                        </div>
                      )}
                    {conflictModalData.start && (
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                        <p>
                          <spam
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            {" "}
                            Start:{" "}
                          </spam>{" "}
                          {JSON.stringify(conflictModalData.start)}
                        </p>
                      </div>
                    )}
                    {conflictModalData.end && (
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                        <p>
                          <spam
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            {" "}
                            End:{" "}
                          </spam>{" "}
                          {JSON.stringify(conflictModalData.end)}
                        </p>
                      </div>
                    )}

                    {conflictModalData.organizer && (
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                        <p>
                          <spam
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            {" "}
                            Organizer:{" "}
                          </spam>{" "}
                          {JSON.stringify(conflictModalData.organizer)}
                        </p>
                      </div>
                    )}

                    {conflictModalData.attendees && (
                      <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                        <p>
                          <spam
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            {" "}
                            Attendees:{" "}
                          </spam>{" "}
                          {JSON.stringify(conflictModalData.attendees)}
                        </p>
                      </div>
                    )}
                    {(conflictModalData.htmlLink ||
                      conflictModalData.webLink) && (
                        <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                          <p>
                            <spam
                              style={{ fontWeight: "bold", marginRight: "10px" }}
                            >
                              {" "}
                              Calendar Type:{" "}
                            </spam>{" "}
                            {conflictModalData.htmlLink
                              ? conflictModalData.htmlLink.includes("google")
                                ? "Google"
                                : conflictModalData.htmlLink
                              : conflictModalData.webLink.includes("outlook")
                                ? "Outlook"
                                : conflictModalData.webLink}
                          </p>
                        </div>
                      )}
                  </div>
                )}
              </Box>
            </Box>
          </Modal>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snakbaropen}
            onClose={handleClose}
            message={snackBarMsg}
            key="bottomcenter"
          />
        </div>
      ) : (
        <div className="thankyou-container canceledAlready">
          <div className="thankyou-block">
            <div className="content">
              <h1>
                Your booked slot has already been canceled, you can no longer
                rescheduled from here.{" "}
              </h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AvailableSlots;
